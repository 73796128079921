<!-- <p>contact works!</p> -->
    <!-- <div class="page-title">
      <div class="container">
        <div class="inner">
          <div class="column">
            <div class="title">
              <h1>Contact Us</h1>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="container" style="padding-top: 55px;">
      <div class="row">
        <div class="col-md-12">
            <div class="site-heading text-center">
                <h2>Contact Us<span></span></h2>
                <h4></h4>
            </div>
        </div>
    </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="alert alert-success d-none mt-4" id="contactSuccess"> <strong>Success!</strong> Your message has been sent to us. </div>
          <div class="alert alert-danger d-none mt-4" id="contactError"> <strong>Error!</strong> There was an error sending your message. <span class="text-1 mt-2 d-block" id="mailErrorMessage"></span> </div>
          <h4 class="mt-4">Get in Touch</h4>
          <form id="contactForm" action="" method="POST" novalidate="novalidate">
            <div class="form-row">
              <div class="form-group col-lg-6">
                <label>Your name *</label>
                <input type="text" value="" data-msg-required="Please enter your name." maxlength="100" class="form-control" name="name" id="name" required="">
              </div>
              <div class="form-group col-lg-6">
                <label>Your email address *</label>
                <input type="email" value="" data-msg-required="Please enter your email address." data-msg-email="Please enter a valid email address." maxlength="100" class="form-control" name="email" id="email" required="">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col">
                <label>Message *</label>
                <textarea maxlength="5000" data-msg-required="Please enter your message." rows="5" class="form-control" name="message" id="message" required=""></textarea>
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col">
                <input type="submit" value="Send Message" class="btn btn-primary btn-lg" data-loading-text="Loading...">
              </div>
            </div>
          </form>
        </div>
        <div class="col-lg-6">
          <h4 class="mt-4">Gujarati Samaj Of Houston</h4>
  
  <ul class="list list-icons list-icons-style-3 mt-4">
      <li><strong>Address: </strong>&nbsp;9550 W. Bellfort St, Houston, TX 77031-2368</li>
      <li><strong>Phone: </strong><a href="tel:+18322792006">+1</a> 281-615-2319</li>
      <li><strong>Email: </strong> <a href="mailto:president@gshouston.org">president@gshouston.org</a></li>
      <li><strong>Support: </strong><a href="https://www.facebook.com/GujaratiSamajofHouston">Like Us Facebook</a></li>
  </ul>
  
  <p><iframe allowfullscreen="" class="mt-4" frameborder="0" height="300" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3466.066222049918!2d-95.58700548529146!3d29.688859682013028!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640dd417645f519%3A0x69c45cac852b62a1!2sHouston%2C+TX+77272%2C+USA!5e0!3m2!1sen!2sin!4v1530357000253" style="border: 0px currentColor; border-image: none;" width="600"></iframe></p>      </div>
      </div>
    </div>
