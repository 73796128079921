<div fxLayout fxLayoutAlign="center center">
  <mat-form-field fxFlex="40%">
    <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="Filter">
  </mat-form-field>
</div>
<div class="mat-elevation-z8">
  <table mat-table [dataSource]="data" matSort class="mat-elevation-z8">

    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> User </th>
      <td mat-cell *matCellDef="let element"> {{element.user}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="userType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> UserType </th>
      <td mat-cell *matCellDef="let element"> {{element.userType}} </td>
    </ng-container>
    <ng-container matColumnDef="ip_address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ip Address </th>
      <td mat-cell *matCellDef="let element"> {{element.ip_address}} </td>
    </ng-container>
    <ng-container matColumnDef="method_type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Method </th>
      <td mat-cell *matCellDef="let element"> {{element.method_type}} </td>
    </ng-container>
    <ng-container matColumnDef="logger_message">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Message </th>
      <td mat-cell *matCellDef="let element"> {{element.logger_message}} </td>
    </ng-container>
    <ng-container matColumnDef="created_at">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
      <td mat-cell *matCellDef="let element"> {{element.created_at| date:'M/d/yy, h:mm a'}} </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
  <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
</div>