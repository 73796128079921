<div class="card card-custom" style="padding-top: 0px;">
    <form [formGroup]="myFormt" (ngSubmit)="onTicketSubmit()">
        <div class="card-body pt-1">
            <i style="float:right" title="back" routerLink="/" class="text-success fa-2x fas fa-arrow-circle-left"></i>
            <div class="py-4">
                <div class="d-flex mb-2">
                    <h3 class="text-primary font-weight-bolder mr-2">Event Ticket Payment Reports</h3>
                    <a class="text-dark text-hover-primary"></a>
                </div>
            </div>
            <button *ngIf="ticreport" type="button" (click)="ticketdownload()" style="float:right;"
                class="m-1 btn btn-primary font-weight-bolder">Download</button>
            <a id="checkout_ticketbutton" target="_blank"
                href="{{apiurl}}/ticketreportdownload/{{finalstartdateticket}}/{{finalenddateticket}}"></a>

            <div class="row align-items-center">
                <div class="col-lg-12 col-xl-8">
                    <div class="row align-items-center">
                        <div class="col-md-4 my-2 my-md-0">
                            <label for="startDate" style="color: rgb(105, 105, 231);font-weight: bold;">Start
                                Date</label><br>
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker" required="true" [max]="maxDate"
                                    formControlName="startDate" (dateChange)="onChangeticket1($event)"
                                    placeholder="Start date" />
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 my-2 my-md-0">
                            <label for="startDate" style="color: rgb(105, 105, 231);font-weight: bold;">End
                                Date</label><br>
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker1" required="true" [max]="maxDate"
                                    formControlName="endDate" (dateChange)="onChangeticket2($event)"
                                    placeholder="End date" />
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 my-2 my-md-0">
                            <div class="input-icon">
                                <button type="reset" id="resetticket" class="btn btn-info mr-4">Cancel</button>
                                <button type="submit" [disabled]="!myFormt.valid"
                                    class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="ticreport" class="table-responsive">
                <table id="users" class="table table-striped table-borderless table-vertical-center">
                    <thead>
                        <tr class="text-left text-uppercase">
                            <th style="min-width: 250px;" class="pl-7">
                                <span class="text-dark-75">Member Information</span>
                            </th>
                            <th style="min-width: 100px;">Transaction Id</th>
                            <th style="min-width: 100px;">Event</th>
                            <th style="min-width: 100px;">Tickets</th>
                            <th style="min-width: 130px;">Price</th>
                            <th style="min-width: 130px;">Event Date</th>
                            <th style="min-width: 130px;">Created Date</th>
                            <!-- <th style="min-width: 80px;"></th> -->
                        </tr>
                    </thead>
                    <tbody *ngFor="let dataItem of tic">
                        <tr>
                            <td class="pl-0 py-8">
                                <div class="d-flex align-items-center">
                                    <div class="symbol symbol-50 symbol-light mr-4">
                                        <div>
                                            <span class="symbol-label">
                                                <span [inlineSVG]="'./assets/media/svg/avatars/001-boy.svg'"
                                                    cacheSVG="true" class="svg-icon h-75 align-self-end"></span>
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        <a
                                            class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg cursor-pointer">{{dataItem.memberName}}</a>
                                        <span class="text-muted font-weight-bold d-block">{{dataItem.email}}</span>
                                        <!-- <span *ngIf="dataItem.productName == 'GSH Event TableFor10 Tickets'" class="text-dark font-weight-bolder d-block"><a href="tel:{{dataItem.dependent}}">{{dataItem.dependent}}</a></span> -->
                                        <span *ngIf="dataItem.productName == 'GSH Event TableFor10 Tickets'"
                                            class="text-dark font-weight-bolder d-block">Table For 10</span>
                                        <span *ngIf="dataItem.productName == 'GSH Event Members Ticket'"
                                            class="text-dark font-weight-bolder d-block">Member </span>
                                        <span *ngIf="dataItem.productName == 'GSH Event Non Member Tickets'"
                                            class="text-dark font-weight-bolder d-block">Non Member</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <span
                                    class="text-dark-75 font-weight-bolder d-block font-size-lg">{{dataItem.transactionID}}</span>
                            </td>
                            <td>
                                <span class="text-dark-75 font-weight-bolder d-block font-size-lg"><a
                                        routerLink="/viewevent/{{dataItem.eventID}}">{{dataItem.eventName}}</a></span>
                                <!-- <span class="text-muted font-weight-bold">Paid</span> -->
                            </td>
                            <td>
                                <span *ngIf="dataItem.productName == 'GSH Event TableFor10 Tickets'"
                                    class="text-dark-75 font-weight-bolder d-block font-size-lg">10</span>
                                <span *ngIf="dataItem.productName != 'GSH Event TableFor10 Tickets'"
                                    class="text-dark-75 font-weight-bolder d-block font-size-lg">{{dataItem.dependent}}</span>
                            </td>
                            <td>
                                <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {{dataItem.price | currency}}</span>
                            </td>
                            <td>
                                <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {{dataItem.eventDate | date:"d, MMM y"}}
                                </span>
                            </td>
                            <td>
                                <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {{dataItem.created_at | date:"d, MMM y"}}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </form>
</div>
<div class="card card-custom" style="padding-top: 3px;">
    <form [formGroup]="myFormm" (ngSubmit)="onMemberSubmit()">
        <div class="card-body pt-1">
            <div class="py-4">
                <div class="d-flex mb-2">
                    <h3 class="text-primary font-weight-bolder mr-2">Member Payment Reports</h3>
                    <a class="text-dark text-hover-primary"></a>
                </div>
            </div>
            <button *ngIf="memreport" type="button" (click)="memberdownload()" style="float:right;"
                class="m-1 btn btn-primary font-weight-bolder">Download</button>
            <a id="checkout_button" target="_blank"
                href="{{apiurl}}/memberreportdownload/{{finalstartdatemember}}/{{finalenddatemember}}"></a>

            <div class="row align-items-center">
                <div class="col-lg-12 col-xl-8">
                    <div class="row align-items-center">
                        <div class="col-md-4 my-2 my-md-0">
                            <label for="startDate" style="color: rgb(105, 105, 231);font-weight: bold;">Start
                                Date</label><br>
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker2" required="true" [max]="maxDate"
                                    formControlName="startDate" (dateChange)="onChangemem1($event)"
                                    placeholder="Start date" />
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 my-2 my-md-0">
                            <label for="startDate" style="color: rgb(105, 105, 231);font-weight: bold;">End
                                Date</label><br>
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker3" required="true" [max]="maxDate"
                                    formControlName="endDate" (dateChange)="onChangemem2($event)"
                                    placeholder="End date" />
                                <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                <mat-datepicker #picker3></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4 my-2 my-md-0">
                            <div class="input-icon">
                                <button type="reset" id="resetmember" class="btn btn-info mr-4">Cancel</button>
                                <button type="submit" [disabled]="!myFormm.valid"
                                    class="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="memreport" class="table-responsive">
                <table id="users" class="table table-striped table-borderless table-vertical-center">
                    <thead>
                        <tr>
                            <th class="pl-7">
                                <span class="text-dark-75">Member Payment Information</span>
                            </th>
                            <th style="min-width: 100px;">Membership Plan</th>
                            <th style="min-width: 100px;">Transaction Id</th>
                            <th style="min-width: 100px;">DESCRIPTION</th>
                            <th style="min-width: 130px;">PRICE</th>
                            <th style="min-width: 130px;">CREATED DATE</th>
                            <!-- <th style="min-width: 80px;"></th> -->
                        </tr>
                    </thead>
                    <tbody *ngFor="let dataItem of mem">
                        <tr>
                            <td class="pl-0 py-8">
                                <div class="d-flex align-items-center">
                                    <div class="symbol symbol-50 symbol-light mr-4">
                                        <span class="symbol-label">
                                            <span [inlineSVG]="'./assets/media/svg/avatars/001-boy.svg'" cacheSVG="true"
                                                class="svg-icon h-75 align-self-end"></span>
                                        </span>
                                    </div>
                                    <div>
                                        <a
                                            class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg cursor-pointer">{{dataItem.memberID.MemberFirstName}}
                                            {{dataItem.memberID.MemberLastName}}</a>
                                        <span class="text-muted font-weight-bold d-block">{{dataItem.email}}</span>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <span
                                    class="text-dark-75 font-weight-bolder d-block font-size-lg">{{dataItem.productName}}</span>
                            </td>
                            <td>
                                <span
                                    class="text-dark-75 font-weight-bolder d-block font-size-lg">{{dataItem.transactionID}}</span>
                            </td>
                            <td>
                                <span *ngIf="dataItem.description == 'initial payment'"
                                    class="text-dark-75 font-weight-bolder d-block font-size-lg">New Member
                                    Register</span>
                                <span *ngIf="dataItem.description == 'renew payment'"
                                    class="text-dark-75 font-weight-bolder d-block font-size-lg"> Member Renewed</span>
                                <span *ngIf="dataItem.description == 'dependent payment'"
                                    class="text-dark-75 font-weight-bolder d-block font-size-lg">Dependent Added</span>
                            </td>
                            <td>
                                <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {{dataItem.price | currency}}</span>
                            </td>
                            <td>
                                <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                                    {{dataItem.created_at | date:"d, MMM y"}}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </form>
</div>