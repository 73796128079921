import { Component, OnInit , ChangeDetectorRef ,Output,EventEmitter} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';    
import { NotificationService } from './../../notification.service'
import {SharedService} from 'src/app/shared.service';
import {Router} from '@angular/router';
@Component({
  selector: 'app-addmember-board',
  templateUrl: './addmember-board.component.html',
  styleUrls: ['./addmember-board.component.scss']
})
export class AddmemberBoardComponent implements OnInit {

  constructor(private formbulider: FormBuilder, private ref: ChangeDetectorRef, private service:SharedService ,private notifyService : NotificationService,private route:Router) { }
  UserForm: any;    
  familyList:any=[]; 
  MembersList:any=[]; 
  content:any=[]; 
  searchText;
  imageSrc: string= "";
  selectedFile:File;
  APIUrl;
  validstart;
  validend;
  passwrd = false;
  passwrd1 = false;
  passwrdcorrect;
  confirmpassword;
  fieldTextType=false;
  fieldTextType1=false;
  @Output() searchcriteria = new EventEmitter<String>();

  public isValidFlg:boolean = true;

  ngOnInit(): void {
    this.APIUrl = this.service.APIUrl;

    this.UserForm = this.formbulider.group({    
      fName: ['',Validators.required],    
      lName: [''],    
      email: ['', [Validators.required , Validators.email]],    
      password: [''],    
      contactPhone: ['',Validators.required],   
      memberID: [''],   
      image: [''],   
      memberBoardType: ['',Validators.required],   
      validityFrom: [''],   
      validityTo: [''],   
    });

    this.service.getMembersList().subscribe(data=>{
      // console.log("data",data);
      this.MembersList=data['member'];
      this.familyList=data['member'];
    },error => {
      console.log(error)
    });
    setInterval(() => { this.ref.detectChanges(); }, 0);

  }
   validatePhoneNo(field) {
    var phoneNumDigits = field.value.replace(/\D/g, '');
  
    this.isValidFlg = (phoneNumDigits.length >= 10);
  
    var formattedNumber = phoneNumDigits;
    if (phoneNumDigits.length >= 10){
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6,10);
    }
      else if (phoneNumDigits.length >= 6){
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6);
    }else if (phoneNumDigits.length >= 3){
        formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3);
    }
  
    field.value = formattedNumber;
  }

  typecheck(val){
    if (this.UserForm.value.memberBoardType == "Temp Admin"){
      this.passwrd = true;
      this.passwrd1 = true;
    }else{
      this.UserForm.value.password = ""
      this.passwrd = false;
      this.passwrd1 = false;
    }
  }

  passcheck(){
    if(this.UserForm.value.password == this.confirmpassword){
      this.passwrdcorrect = "password matched"
    }else{
      this.passwrdcorrect = "password not matching"
    }
  }


  viewpassword(){
    this.fieldTextType = true;
  }
  viewpassword1(){
    this.fieldTextType1 = true;
  }

  hidepassword(){
    this.fieldTextType = false;
  }
  hidepassword1(){
    this.fieldTextType1 = false;
  }
    cancel(){
      this.route.navigate(['/listMemberBoard']); // navigate to other page
    }

    onChangeEvent6(data){
      this.validstart = new Date(data.value).toISOString().split("T")[0]
    }
    onChangeEvent(data){
      this.validend = new Date(data.value).toISOString().split("T")[0]
    }


  searchThis(data) {
    this.content = this.MembersList
    if (data) {
      var item = this.MembersList.filter(item => 
        {
          // data = data.toLowerCase()
          // if(item.MemberFirstName != "" || item.MemberFirstName != null || item.MemberFirstName != undefined){            
          //   if (item.MemberFirstName.toLowerCase().includes(data.toLowerCase())) {
          //     console.log("firstname")           
          //     return item;
          //   }
          // }
          if(item.MemberLastName != "" || item.MemberLastName != null || item.MemberLastName != undefined){ 
            if (item.MemberLastName.toLowerCase().includes(data.toLowerCase())){
            return item;
          }}
        });    
        this.familyList = item;
        if (item[0]){
          this.UserForm.patchValue({
            fName: item[0]['MemberFirstName'] || "",
            lName: item[0]['MemberLastName'] || "",
            email: item[0]['email'] || "",
            contactPhone: item[0]['contactPhone'] || "",
            image: item[0]['memberImage'] || "",
            memberID: item[0]['MemberId'] || "",
            validityFrom: item[0]['validityFrom'] || "",
            validityTo: item[0]['validityTo'] || "",
          }); 
        }        
    }
    else{
      this.familyList =  this.MembersList;
      this.UserForm.patchValue({
        fName: "",
        lName: "",
        email: "",
        contactPhone: "",
        image:"",
        memberID:"",
        validityFrom:"",
        validityTo:"",
     });
    }
  }
  deletePic() {
    // this.formGroup.value.pic = '';
    this.imageSrc = '';
    this.selectedFile = null;
  }

  onFileChange(event) {
    const reader = new FileReader();
    this.selectedFile = event.target.files[0]

    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
    
      reader.onload = () => {
           this.imageSrc = reader.result as string;    
   };
   }
  }

  memberselected(memberid){
      // console.log("member",memberid.target.value)
      // console.log(this.MembersList)
      var newvalue = this.MembersList.filter(item => parseInt(item.MemberId) === parseInt(memberid.target.value));  
      // console.log("newvalue",newvalue)
      // console.log("newvalue",newvalue[0]['MemberFirstName'])
      this.UserForm.patchValue({
        fName: newvalue[0]['MemberFirstName'] || "",
        lName: newvalue[0]['MemberLastName'] || "",
        email: newvalue[0]['email'] || "",
        contactPhone: newvalue[0]['contactPhone'] || "",
        image: newvalue[0]['memberImage'] || "",
        memberID: newvalue[0]['MemberId'] || "",
        validityFrom: newvalue[0]['validityFrom'] || "",
        validityTo: newvalue[0]['validityTo'] || "",
     }); 
  }
   onFormSubmit()  {    
    const user = this.UserForm.value; 
    if (this.UserForm.value.memberID){
      user.memberID = parseInt(this.UserForm.value.memberID) 
    }else{
      user.memberID = ''
    }
    // user.memberID = parseInt(this.UserForm.value.memberID) 
    // console.log("user",user)
    const uploadData1 = new FormData();
      if (this.selectedFile){ 
        uploadData1.append('image', this.selectedFile); }
      if (this.validstart) { 
        uploadData1.append('validityFrom', this.validstart); }
      else { 
        uploadData1.append('validityFrom', this.UserForm.value.validityFrom); }
      if (this.validend) { 
        uploadData1.append('validityTo', this.validend); }
      else { 
        uploadData1.append('validityTo', this.UserForm.value.validityTo); }
      if(this.UserForm.value.memberBoardType == "Temp Admin"){
        uploadData1.append('is_staff', "True"); }
      uploadData1.append('fName', this.UserForm.value.fName);
      uploadData1.append('lName', this.UserForm.value.lName);
      uploadData1.append('email', this.UserForm.value.email);
      uploadData1.append('contactPhone', this.UserForm.value.contactPhone);
      uploadData1.append('memberID', user.memberID);
      uploadData1.append('memberBoardType', this.UserForm.value.memberBoardType);
      if(this.UserForm.value.password){
        uploadData1.append('password', this.UserForm.value.password); 
      }
    this.service.addMemberShipBoard(uploadData1)
      .subscribe(
        data => {
          if (data['email']){
            this.notifyService.showWarning(data['email'], "Error")
          }else{
            // console.log(data);
            this.notifyService.showSuccess(data, "Created")
            this.route.navigate(['/listMemberBoard']); // navigate to other page
          }
              },
        error => {
          console.log("error",error.error)
          this.notifyService.showError(error.error, "Error")

          // this.errors= true;
          // this.error_data = error.error
        });  
  }    
}
