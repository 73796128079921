import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import { AuthService } from './modules/auth/_services/auth.service';
import { FakeAPIService } from './_fake/fake-api.service';
import { environment } from 'src/environments/environment';
// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';
// Family Components
import { FamilyComponent } from './family/family.component';
import { ShowFamilyComponent } from './family/show-family/show-family.component';
// import { AddEditFamilyComponent } from './family/add-edit-family/add-edit-family.component';
import { MemberComponent } from './member/member.component';
// import { AddMemberComponent } from './member/add-member/add-member.component';
import { ShowMemberComponent } from './member/show-member/show-member.component';
import { ToastrModule } from 'ngx-toastr';
// import { UpdateFamilyComponent } from './family/update-family/update-family.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { NgxPaginationModule } from 'ngx-pagination';
import { MembershipPlanComponent } from './member/membership-plan/membership-plan.component';
// Shared Service 
import { SharedService} from './shared.service';
import { MembershipBoardComponent } from './member/membership-board/membership-board.component';
import { AddmemberBoardComponent } from './member/addmember-board/addmember-board.component';
import { BlankPipe } from './blank.pipe';
import { AboutComponent } from './gshinfo/about/about.component';
import { ContactComponent } from './gshinfo/contact/contact.component';
import { LeadershipComponent } from './gshinfo/leadership/leadership.component';
import { MatTableModule } from '@angular/material/table'  
import {MatSortModule} from '@angular/material/sort';
import { LogsComponent } from './gshinfo/logs/logs.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CheckoutComponent } from './gshinfo/checkout/checkout.component';
import { CheckoutTicketNonmemberComponent } from './gshinfo/checkout-ticketNonmember/checkout-ticketNonmember.component';
import { CheckoutTicketMemberComponent } from './gshinfo/checkout-ticket-member/checkout-ticket-member.component';
import { CheckoutRenewComponent } from './gshinfo/checkout-renew/checkout-renew.component';
import { ReportsComponent } from './gshinfo/reports/reports.component';
import { CheckoutTablefortenComponent } from './gshinfo/checkout-tableforten/checkout-tableforten.component';


function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

@NgModule({
  declarations: [AppComponent,    
    FamilyComponent,
    ShowFamilyComponent,
    // AddEditFamilyComponent,
    MemberComponent,
    // AddMemberComponent,
    ShowMemberComponent,
    // UpdateFamilyComponent,
    MembershipPlanComponent,
    MembershipBoardComponent,
    AddmemberBoardComponent,
    BlankPipe,
    AboutComponent,
    ContactComponent,
    LeadershipComponent,
    LogsComponent,
    CheckoutComponent,
    CheckoutTicketNonmemberComponent,
    CheckoutTicketMemberComponent,
    CheckoutRenewComponent,
    ReportsComponent,
    CheckoutTablefortenComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgImageSliderModule,
    NgxPaginationModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSnackBarModule,
    MatMomentDateModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot(),
    HighlightModule,
    ClipboardModule,
    environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
        passThruUnknownUrl: true,
        dataEncapsulation: false,
      })
      : [],
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgbModule,
  ],
  providers: [SharedService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        coreLibraryLoader: () => import('highlight.js/lib/core'),
        languages: {
          xml: () => import('highlight.js/lib/languages/xml'),
          typescript: () => import('highlight.js/lib/languages/typescript'),
          scss: () => import('highlight.js/lib/languages/scss'),
          json: () => import('highlight.js/lib/languages/json')
        },
      },
    },
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true }},
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
