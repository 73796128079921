import { Component, OnInit , ChangeDetectorRef} from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import {SharedService} from 'src/app/shared.service';
import { NotificationService } from 'src/app/notification.service'

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  maxDate = new Date();
  myFormt;
  myFormm;
  apiurl;
  finalstartdatemember
  finalstartdateticket
  finalenddatemember
  finalenddateticket
  memreport=false;
  ticreport=false;
  tic:any=[]; 
  mem:any=[]; 


  constructor(private service:SharedService , private notifyService : NotificationService ,private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.myFormt = new FormGroup({
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
    });
    this.myFormm = new FormGroup({
      startDate: new FormControl('', [Validators.required]),
      endDate: new FormControl('', [Validators.required]),
    });
    this.apiurl = this.service.APIUrl;
  }

  onTicketSubmit(){
    const uploadData = new FormData();
    uploadData.append('startDate', new Date(this.myFormt.value.startDate).toISOString().split("T")[0]);
    uploadData.append('endDate', new Date(this.myFormt.value.endDate).toISOString().split("T")[0]);
    this.service.ticketreport(uploadData).subscribe(data => {
      if (data['success']){
        // console.log("data['success']",data['success'])
        this.tic=data['success'];
        this.ticreport=true;
      }
      else{
        this.notifyService.showWarning('No Ticket Data Found',"Ticket Info")
      }
    });
    setInterval(() => { this.ref.detectChanges(); }, 0);

  }


  onMemberSubmit(){
    const uploadData = new FormData();
    uploadData.append('startDate', new Date(this.myFormm.value.startDate).toISOString().split("T")[0]);
    uploadData.append('endDate', new Date(this.myFormm.value.endDate).toISOString().split("T")[0]);
    this.service.memberreport(uploadData).subscribe(data => {
      if (data['success']){
        // console.log("data member ['success']",data['success'])

        this.mem = data['success'];
        this.memreport=true;
      }
      else{
        this.notifyService.showWarning('No Member Data Found',"Member Info")
      }
    });
    setInterval(() => { this.ref.detectChanges(); }, 0);

  }
  

  onChangeticket1(event){
    if (event.value == null){ return; }
    this.finalstartdateticket = new Date(event.value).toISOString().split("T")[0];
    this.ticreport= false;
    }

  onChangeticket2(event){
    if (event.value == null){ return; }
    this.finalenddateticket = new Date(event.value).toISOString().split("T")[0];
    this.ticreport= false;
    }
  onChangemem1(event){
    if (event.value == null){ return; }
    this.finalstartdatemember = new Date(event.value).toISOString().split("T")[0];
    this.memreport=false;
    }

    onChangemem2(event){
      if (event.value == null){ return; }
      this.finalenddatemember = new Date(event.value).toISOString().split("T")[0];
      this.memreport=false;
      }
  memberdownload(){
    document.getElementById("checkout_button").click();
    this.memreport=false;
    document.getElementById("resetmember").click();

  }
  ticketdownload(){
    document.getElementById("checkout_ticketbutton").click();
    this.ticreport=false;
    document.getElementById("resetticket").click();

  }

}
