<!-- <p>about works!</p> -->
<!-- <div role="main" class="main">
    <div class="page-title">
<div class="container">
<div class="inner">
<div class="column">
<div class="title">
<h1>GSH Information</h1>
</div>
</div>
</div>
</div>
</div>
<br><br> -->

<div class="container" style="padding-top: 55px;">
  <div class="row">
    <div class="col-md-12">
        <div class="site-heading text-center">
            <h2>About Gujarati Samaj of Houston<span></span></h2>
            <h4></h4>
        </div>
    </div>
</div>
<div class="row">
<div class="col-sm-12 col-lg-12">
<h4><b>About Us :</b></h4>

<p>Founded in 1979,GSH organizes educational and cultural activities to promote the Indian heritage, and is registered as a Non-profit organization under 501(C)(3). Our mission is to create an innovative, inclusive and ideal "Gujarati Samaj Community Center" to implement our Vision and activities. . We believe we must nurture the youth of our community to become leaders of tomorrow in all aspects and activities. We must create educational, developmental, fitness and training resources for the youth and the adult members of our communities. We also must nourish our senior citizens to provide opportunities for inter-generational exchange of knowledge and integrate them to become integral part of our Samaj. The mission of Gujarati Samaj of Houston is to promote our culture&nbsp;and heritage and pass on the leadership to the upcoming youth.</p>

<h4 class="mt-5"> <b>Activities of the Samaj:</b></h4>

<p>Our Main Activities of the Samaj are&nbsp;Kite Flying, Holi, Navaratri, Diwali, Picnic, Eductional seminars, Holi discourses.&nbsp; We pride in the fact that as a Gujarati community,&nbsp;we are prosporous and leaders in many fields. Our new State of the Art GSH Event&nbsp;Center is now&nbsp;ready which&nbsp;will give us&nbsp;more oppurtunities to network and continue many of these activities to promote our rich heritage. &nbsp;</p>

<p>&nbsp;</p>
</div>
</div>
</div>
