<div class="table-responsive">
  <table id="users" class="table table-striped w-auto">
    <thead>
      <tr>
        <th>Membership Type</th>
        <th>Prefix</th>
        <th>Plan Fee ($)</th>
        <th>Have Spouse</th>
        <th>Dependent Fee($)</th>
        <th>Max Dependent</th>
        <th>Service Charge(%)</th>
        <th>Validity Type</th>
        <th>Status</th>
        <th>Validity Start</th>
        <th>Validity End</th>
        <th>Action</th>
    </thead>
    <tbody>
      <!-- <tr (click)="viewUser(dataItem)"  *ngFor="let dataItem of memberplanList"> -->
      <tr *ngFor="let dataItem of memberplanList">
        <td contenteditable="true" (input)="dataItem.MembershipType=$event.target.textContent"
          [textContent]="dataItem.MembershipType">
          {{dataItem.MembershipType}}</td>
        <td>
          <div (input)="dataItem.prefix=$event.target.textContent">{{dataItem.prefix}}</div>
        </td>
        <td>
          <div contenteditable="true" [textContent]="dataItem.PlanFee"
            (input)="dataItem.PlanFee=$event.target.textContent">{{dataItem.PlanFee}}</div>
        </td>

        <!-- <td [textContent]="dataItem?.haveSpouse" (input)="dataItem.haveSpouse=$event.target.textContent" contenteditable='true'*ngIf='dataItem.haveSpouse'> -->
        <td style="width:10%" (input)="dataItem.haveSpouse=$event.target.value" *ngIf='dataItem.haveSpouse'>
          <select class="form-control greenText">
            <option value=True>Yes</option>
            <option value=False>No</option>
          </select>
        </td>
        <td style="width:10%" (input)="dataItem.haveSpouse=$event.target.value" *ngIf='!dataItem.haveSpouse'>
          <!-- <td [textContent]="dataItem?.haveSpouse" (input)="dataItem.haveSpouse=$event.target.textContent"*ngIf='!dataItem.haveSpouse'> -->
          <select class="form-control greenText">
            <option value=False>No</option>
            <option value=True>Yes</option>
          </select>
        </td>
        <!-- <td *ngIf='!plan' id="planFee"> {{dataItem.PlanFee}} </td> -->
        <td>
          <div contenteditable="true" [textContent]="dataItem.predependentFee"
            (input)="dataItem.predependentFee=$event.target.textContent">{{dataItem.predependentFee}}</div>
        </td>
        <td>
          <div contenteditable="true" [textContent]="dataItem.maxDependent"
            (input)="dataItem.maxDependent=$event.target.textContent">{{dataItem.maxDependent}}</div>
        </td>
        <td>
          <div contenteditable="true" [textContent]="dataItem.serviceCharge"
            (input)="dataItem.serviceCharge=$event.target.textContent">{{dataItem.serviceCharge}}</div>
        </td>
        <!-- <td><span contenteditable="true" (keyup)="dataItem.serviceCharge=$event.target.textContent">{{dataItem.serviceCharge}}</span></td> -->
        <!-- <td (input)="dataItem.validityType=$event.target.textContent" contenteditable='true'>{{dataItem.validityType}}</td> -->
        <td style="white-space:nowrap ;width:10%" (input)="dataItem.validityType=$event.target.value"
          *ngIf='dataItem.validityType == "Limited"'>
          <select class="form-control greenText">
            <option value="Limited">Limited</option>
            <option value="Unlimited">Unlimited</option>
          </select>
        </td>
        <td style="white-space:nowrap ;width:10%" (input)="dataItem.validityType=$event.target.value"
          *ngIf='dataItem.validityType == "Unlimited"'>
          <!-- <td [textContent]="dataItem?.haveSpouse" (input)="dataItem.haveSpouse=$event.target.textContent"*ngIf='!dataItem.haveSpouse'> -->
          <select class="form-control greenText">
            <option value="Unlimited">Unlimited</option>
            <option value="Limited">Limited</option>
          </select>
        </td>

        <!-- <td *ngIf='dataItem.is_active'>Active</td>
            <td *ngIf='!dataItem.is_active'>InActive</td> -->
        <td style="width:10%" (input)="dataItem.is_active=$event.target.value" *ngIf='dataItem.is_active'>
          <select class="form-control greenText">
            <option value=True>Active</option>
            <option value=False>InActive</option>
          </select>
        </td>
        <td style="width:10%" (input)="dataItem.is_active=$event.target.value" *ngIf='!dataItem.is_active'>
          <!-- <td [textContent]="dataItem?.haveSpouse" (input)="dataItem.haveSpouse=$event.target.textContent"*ngIf='!dataItem.haveSpouse'> -->
          <select class="form-control greenText">
            <option value=False>InActive</option>
            <option value=True>Active</option>
          </select>
        </td>
        <!-- (dateChange)="onChangeEvent6($event)" -->
        <td><mat-form-field>
            <input matInput (dateChange)="onChangeEvent6($event)" [matDatepicker]="picker"
              value="{{dataItem.validityFrom}}" placeholder="Choose a date" />
            <mat-datepicker-toggle matSuffix [for]="picker">`
            </mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field></td>
        <td><mat-form-field>
            <input matInput [matDatepicker]="picker1" placeholder="Choose a date" (dateChange)="onChangeEvent($event)"
              value="{{dataItem.validityTo}}" />
            <mat-datepicker-toggle matSuffix [for]="picker1">
            </mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field></td>
        <td>
          <!-- <button type="button" class="btn btn-light mr-1"  (click)='updateIDFamily(dataItem.FamilyId)'>Edit</button> -->
          <button type="button" (click)="viewUser(dataItem);"
            class="m-1 btn btn-light-success btn-success font-weight-bold">View</button>
          <button type="button" (click)="updateMemberShip(dataItem);"
            class="m-1 btn btn-light-success btn-success font-weight-bold mr-2">Update</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<span id="listhere"></span>
<div class="mt-2 mb-10">
  <span class="price-display mr-2">Darpan Magazine Price: ${{ darpanprice }}</span>
  <button type="button" (click)="viewdarpan = !viewdarpan" class="btn btn-success btn-sm">
    Update
  </button>
</div>
<div *ngIf="viewdarpan" class="darpan-price-container d-flex align-items-center">
  <label for="memberID" class="font-weight-bolder text-dark">Enter New Price</label>
  <input type="text" pattern="^\d+(\.\d{1,2})?$" [(ngModel)]="darpanprice" placeholder="Enter new price"
    class="form-control form-control-sm" min="0" style="width: 120px;" />
  <button type="button" (click)="updateDarpanPrice()" class="btn btn-success btn-sm ml-2">
    Update Price
  </button>
</div>
<div *ngIf="isuser" class="view-details">
  <ul>
    <li>Membership Type: {{userObj.MembershipType}}</li>
    <li>Prefix: {{userObj.prefix}}</li>
    <li>Have Spouse: {{userObj.haveSpouse}}</li>
    <li>Plan Fee ($): {{userObj.PlanFee}}</li>
    <li>Max Dependent: {{userObj.maxDependent}}</li>
    <li>Dependent Fee($): {{userObj.predependentFee}}</li>
    <li>Service Charge(%): {{userObj.serviceCharge}}</li>
    <li>Validity Type: {{userObj.validityType}}</li>
    <li>Status: {{userObj.is_active}}</li>
  </ul>
</div>


<div *ngIf="isedit">
  <form #myForm="ngForm">
    <div class="form-group">
      <label>Status</label>
      <!-- <select name="is_active" class="form-control" [(ngModel)]="userObj.is_active">
        ny two ways pass data in ngmodel    
        -->
      <select name="is_active" class="form-control" [(ngModel)]="userObj.is_active">
        <option>true</option>
        <option>false</option>
        <!-- <option>{{userObj.is_active}}</option> -->
      </select>
      <label>Spouse</label>

      <select name="haveSpouse" class="form-control" [(ngModel)]="userObj.haveSpouse">
        <option>true</option>
        <option>false</option>
        <!-- <option>{{userObj.is_active}}</option> -->
      </select>
      <label>PlanFee</label><br>
      <input type="number" name="PlanFee" [(ngModel)]="userObj.PlanFee">
    </div>
    <button type="button" class="btn btn-success btn-sm btn-update mr-2">Update</button>
    <button type="button" class="btn btn-secondary btn-sm">Cancel</button>
  </form>
</div>