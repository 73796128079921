<!-- <div [hidden]="select">
<table id ="users" class="table table-striped">
   <thead>
       <tr>
           <th>FamilyID</th>
           <th>Family Name</th>
       <th>Actions</th>
   </thead>
   <tbody>
       <tr *ngFor="let dataItem of FamilyList">
           <td title="select to view members" (click)='idMemberFamilyList(dataItem.FamilyId)'>{{dataItem.FamilyId}}</td>
           <td title="select to view members" (click)='idMemberFamilyList(dataItem.FamilyId)'>{{dataItem.FamilyName}}</td>
           <td>
               <button type="button" class="m-1 btn btn-light-success btn-success font-weight-bold"  (click)='updateIDFamily(dataItem.FamilyId)'>Edit</button>
               <button type="button" class="m-1 btn btn-light-danger btn-success font-weight-bold mr-1" (click)='deleteFamily(dataItem.FamilyId)'>Delete</button>
           </td>
       </tr>
   </tbody>
</table>
</div> -->

<!-- <div [hidden]="!select">
<div *ngFor="let dataItem of FamilyLists" style="width: 300px;">
    <div *ngIf="dataItem">
        <div>
          <label for="FamilyId">FamilyId </label>
          <input type="text" class="form-control" id="FamilyId" readonly  value= "{{dataItem.FamilyId}}" name="FamilyId">
        </div>
        <div><br>
          <label for="FamilyName">FamilyName </label>
          <input type="text" class="form-control" id="FamilyName" required value="{{dataItem.FamilyName}}" name="FamilyName">
        </div>
        <div>
        <br>
        <span class="btn btn-success" (click)='updateFamily(dataItem.FamilyId)'>Update</span>
      </div>
  </div>
  </div>
</div> -->
<button type="button" style="float:right;" (click)="backClicked()"
  class="mb-2 btn btn-info font-weight-bolder">Cancel
  <span class="svg-icon svg-icon-md ml-2">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px"
      viewBox="0 0 24 24" version="1.1">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <polygon points="0 0 24 0 24 24 0 24" />
        <rect fill="#000000" opacity="0.3"
          transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5" y="7.5"
          width="2" height="9" rx="1" />
        <path
          d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
          fill="#000000" fill-rule="nonzero"
          transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
      </g>
    </svg>
  </span></button>
<button *ngIf="memberslogin" type="button" title="select to view family" routerLink="/add"
  [state]="{ id:1 , role:'super_admin'}" class="btn btn-primary font-weight-bold">Add/Edit
  Member</button>
<div *ngIf="members" class="table-responsive">
  <!-- <h3 style="text-align: center; color:blue"> List Members</h3> -->
  <table id="users" class="table table-striped" *ngFor="let dataItem of memberFamily">
    <thead>
      <th>Family ID</th>
      <th>Member Name</th>
      <th>Membership Type</th>
      <th>Address</th>
      <th>Email</th>
      <th>Contact Phone</th>
      <th>Date of Birth</th>
      <th>Relation</th>
      <th>Action</th>
    </thead>
    <tbody>
      <tr>
        <td>{{dataItem.FamilyPlanId}}</td>
        <td>{{dataItem.MemberFirstName}} {{dataItem.MemberLastName}}</td>
        <td>{{dataItem.MembershipType}}</td>
        <td style='white-space: nowrap' *ngIf="dataItem.street">{{dataItem.street}},<br> {{dataItem.city}}
          {{dataItem.state}} {{dataItem.zipcode}}.</td>
        <td style='white-space: nowrap' *ngIf="!dataItem.street">{{dataItem.city}} {{dataItem.state}}
          {{dataItem.zipcode}}.</td>
        <td>{{dataItem.email}}</td>
        <td>{{validateinitialPhoneNo(dataItem.contactPhone)}}</td>
        <td>{{dataItem.dateOfBirth | date:"MMM d, y"}}</td>
        <td>{{dataItem.relation}}</td>
        <td> <button type="button" title="select to view family" routerLink="/add"
            [state]="{accessfamilytoken : dataItem.access_token}" class="btn btn-success font-weight-bold">Add/Edit
            Member</button></td>
        <!-- <td>  <button type="button" (click)="onRowClick(dataItem);" class="m-1 btn btn-light-success btn-success font-weight-bold">Edit</button></td>           -->
        <!-- <td>  <button type="button" title="select to view family" routerLink="/add" class="m-1 btn btn-light-success btn-success font-weight-bold">Add Member</button></td>           -->
      </tr>
      <tr *ngIf="dataItem.spouseFirstName">
        <td>{{dataItem.FamilyPlanId}}</td>
        <td>{{dataItem.spouseFirstName}} {{dataItem.spouseLastName}}</td>
        <td>{{dataItem.MembershipType}}</td>
        <td style='white-space: nowrap' *ngIf="dataItem.street">{{dataItem.street}},<br> {{dataItem.city}}
          {{dataItem.state}} {{dataItem.zipcode}}.</td>
        <td style='white-space: nowrap' *ngIf="!dataItem.street">{{dataItem.city}} {{dataItem.state}}
          {{dataItem.zipcode}}.</td>
        <td>{{dataItem.spouseemail}}</td>
        <td>{{validateinitialPhoneNo(dataItem.spousecontactPhone)}}</td>
        <td>{{dataItem.spousedateOfBirth | date:"MMM d, y"}}</td>
        <td>Spouse</td>
        <td> <button type="button" (click)="deletespouse(dataItem)" class="btn btn-danger font-weight-bold">Delete</button></td>

      </tr>
      <tr *ngIf="dataItem.dependname1">
        <td>{{dataItem.FamilyPlanId}}</td>
        <td>{{dataItem.dependname1}}</td>
        <td>{{dataItem.MembershipType}}</td>
        <td style='white-space: nowrap' *ngIf="dataItem.street">{{dataItem.street}},<br> {{dataItem.city}}
          {{dataItem.state}} {{dataItem.zipcode}}.</td>
        <td style='white-space: nowrap' *ngIf="!dataItem.street">{{dataItem.city}} {{dataItem.state}}
          {{dataItem.zipcode}}.</td>
        <td></td>
        <td></td>
        <td>{{dataItem.dependdob1 | date:"MMM d, y"}}</td>
        <td>Dependent</td>
        <td> <button type="button" (click)="deletedep1(dataItem)" class="btn btn-danger font-weight-bold">
            Delete</button></td>

      </tr>
      <tr *ngIf="dataItem.dependname2">
        <td>{{dataItem.FamilyPlanId}}</td>
        <td>{{dataItem.dependname2}}</td>
        <td>{{dataItem.MembershipType}}</td>
        <td style='white-space: nowrap' *ngIf="dataItem.street">{{dataItem.street}},<br> {{dataItem.city}}
          {{dataItem.state}} {{dataItem.zipcode}}.</td>
        <td style='white-space: nowrap' *ngIf="!dataItem.street">{{dataItem.city}} {{dataItem.state}}
          {{dataItem.zipcode}}.</td>
        <td></td>
        <td></td>
        <td>{{dataItem.dependdob2 | date:"MMM d, y"}}</td>
        <td>Dependent</td>
        <td> <button type="button" (click)="deletedep2(dataItem)" class="btn btn-danger font-weight-bold">Delete</button></td>

      </tr>
      <tr *ngIf="dataItem.dependname3">
        <td>{{dataItem.FamilyPlanId}}</td>
        <td>{{dataItem.dependname3}}</td>
        <td>{{dataItem.MembershipType}}</td>
        <td style='white-space: nowrap' *ngIf="dataItem.street">{{dataItem.street}},<br> {{dataItem.city}}
          {{dataItem.state}} {{dataItem.zipcode}}.</td>
        <td style='white-space: nowrap' *ngIf="!dataItem.street">{{dataItem.city}} {{dataItem.state}}
          {{dataItem.zipcode}}.</td>
        <td></td>
        <td></td>
        <td>{{dataItem.dependdob3 | date:"MMM d, y"}}</td>
        <td>Dependent</td>
        <td> <button type="button" (click)="deletedep3(dataItem)" class="btn btn-danger font-weight-bold">Delete</button></td>

      </tr>
      <tr *ngIf="dataItem.dependname4">
        <td>{{dataItem.FamilyPlanId}}</td>
        <td>{{dataItem.dependname4}}</td>
        <td>{{dataItem.MembershipType}}</td>
        <td style='white-space: nowrap' *ngIf="dataItem.street">{{dataItem.street}},<br> {{dataItem.city}}
          {{dataItem.state}} {{dataItem.zipcode}}.</td>
        <td style='white-space: nowrap' *ngIf="!dataItem.street">{{dataItem.city}} {{dataItem.state}}
          {{dataItem.zipcode}}.</td>
        <td></td>
        <td></td>
        <td>{{dataItem.dependdob4 | date:"MMM d, y"}}</td>
        <td>Dependent</td>
        <td> <button type="button" (click)="deletedep4(dataItem)" class="btn btn-danger font-weight-bold">Delete</button></td>

      </tr>
      <tr *ngIf="dataItem.dependname5">
        <td>{{dataItem.FamilyPlanId}}</td>
        <td>{{dataItem.dependname5}}</td>
        <td>{{dataItem.MembershipType}}</td>
        <td style='white-space: nowrap' *ngIf="dataItem.street">{{dataItem.street}},<br> {{dataItem.city}}
          {{dataItem.state}} {{dataItem.zipcode}}.</td>
        <td style='white-space: nowrap' *ngIf="!dataItem.street">{{dataItem.city}} {{dataItem.state}}
          {{dataItem.zipcode}}.</td>
        <td></td>
        <td></td>
        <td>{{dataItem.dependdob5 | date:"MMM d, y"}}</td>
        <td>Dependent</td>
        <td> <button type="button" (click)="deletedep5(dataItem)" class="btn btn-danger font-weight-bold">Delete</button></td>

      </tr>
      <tr *ngIf="dataItem.dependname6">
        <td>{{dataItem.FamilyPlanId}}</td>
        <td>{{dataItem.dependname6}}</td>
        <td>{{dataItem.MembershipType}}</td>
        <td style='white-space: nowrap' *ngIf="dataItem.street">{{dataItem.street}},<br> {{dataItem.city}}
          {{dataItem.state}} {{dataItem.zipcode}}.</td>
        <td style='white-space: nowrap' *ngIf="!dataItem.street">{{dataItem.city}} {{dataItem.state}}
          {{dataItem.zipcode}}.</td>
        <td></td>
        <td></td>
        <td>{{dataItem.dependdob6 | date:"MMM d, y"}}</td>
        <td>Dependent</td>
        <td> <button type="button" (click)="deletedep6(dataItem)" class="btn btn-danger font-weight-bold">Delete</button></td>

      </tr>
    </tbody>
  </table>
</div>


<div *ngIf="nomemberselected">
  <div class="card card-custom gutter-b">
    <div class="card-header">
      <div class="card-title">
        <a>
          <h3 class="card-label loanchart">Select Member From List To View Family Details</h3>
        </a>
        <button type="button" routerLink="/member" title="View member to continue"
          class="m-1 btn btn-info font-weight-bolder">List Member<span class="svg-icon svg-icon-md ml-2">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
              height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24" />
                <rect fill="#000000" opacity="0.3"
                  transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)" x="7.5"
                  y="7.5" width="2" height="9" rx="1" />
                <path
                  d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                  fill="#000000" fill-rule="nonzero"
                  transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
              </g>
            </svg>
          </span></button>
      </div>
    </div>
  </div>