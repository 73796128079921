// import { Injectable } from '@angular/core';
// import {
//   Router,
//   CanActivate,
//   ActivatedRouteSnapshot,
//   RouterStateSnapshot,
// } from '@angular/router';
// import { AuthService } from './auth.service';

// @Injectable({ providedIn: 'root' })
// export class AuthGuard implements CanActivate {
//   constructor(private authService: AuthService) {}

//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
//     const currentUser = this.authService.currentUserValue;
//     if (currentUser) {
//       // logged in so return true
//       return true;
//     }
    
//     // not logged in so redirect to login page with the return url
//     this.authService.logout();
//     return false;
//   }
// }


import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';
import { SharedService} from "./../../../shared.service"
import { environment } from 'src/environments/environment';
// import { userInfo } from 'os';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService , private router: Router ,private service: SharedService) {}
  user:Boolean;
  
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // const currentUser = this.authService.currentUserValue;
    const authDataGA = JSON.parse(
      localStorage.getItem(this.authLocalStorageToken+"ga")
    );

    const authDataMem = JSON.parse(
      localStorage.getItem(this.authLocalStorageToken+"mem")
    );

    const authDataSU = JSON.parse(
      localStorage.getItem(this.authLocalStorageToken+"su")
    );

    // const currentUser = this.service.accToken(authData).subscribe(data => {
    //   console.log("access found")
    //   this.user = true
    // });
    // console.log(currentUser)
    if (authDataGA) {
      // console.log("true")
      // this.service.accTokengsh(authDataGA).subscribe(authState => {
      //   if (authState == "No Access Token Found"){
      //     this.authService.logout();
      //     return false;
      //   }
      // });
      // logged in so return true
      return true;
    }else if (authDataSU){
      return true;
    }else if (authDataMem){
      return true;
    }else{
      let url: string = state.url;
      this.authService.logout();
      this.router.navigate(['/auth/memberlogin'], {queryParams: {returnUrl: state.url},skipLocationChange: true});
      return false;
    }
      // not logged in so redirect to login page with the return url
      // this.authService.logout();
      // return false;
  }
}
