import { Component, OnInit ,ChangeDetectorRef ,Input } from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import {Family} from './../../models/family-add';
import { NotificationService } from './../../notification.service'
import {Router} from '@angular/router';
import * as $ from 'jquery'
import { environment } from 'src/environments/environment';
import {Location} from '@angular/common';
import { ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-show-family',
  templateUrl: './show-family.component.html',
  styleUrls: ['./show-family.component.css']
})
export class ShowFamilyComponent implements OnInit {
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  @Input() family: Family;
  select= false;
  members =false;
  memberslogin = false;
  email: string;
  trans: string;
  dependent;
  member_id: string;
  amount;
  curDate=new Date();
  datae;
  nomemberselected = false;
  //families : Family = new Family();
  //famil = null;
  constructor(private service:SharedService , private activatedRoute: ActivatedRoute,private ref: ChangeDetectorRef, private notifyService : NotificationService ,private route:Router ,private _location: Location) { }

  FamilyList:any=[]; 
  FamilyLists:any=[]; 
  MembersList:any=[]; 
  memberFamily:any=[]; 

  ngOnInit(): void {  
    this.getrouter();
    const authDataMem = JSON.parse(
      localStorage.getItem(this.authLocalStorageToken+"mem")
      );
      const authDataGA = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken+"ga")
      );
      const authDataSU = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken+"su")
      );
      if (authDataMem){
        this.memberslogin = true;
      }
      this.refreshFamilyList(authDataMem);  
      if (this.email || this.member_id || this.trans){
        var data = { "email" : this.email ,"member_id":this.member_id,"transactionID" : this.trans ,"dependent" : this.dependent , "productName":"Additional Dependent Price	","price":this.amount ,"description":"dependent payment"}
        this.service.paymentdependent(data).subscribe(data=> {
          this.notifyService.showSuccess("Payment SuccessFull","Member Updated")
        })
      }
      setInterval(() => { this.ref.detectChanges(); }, 0);

  } 

  validateinitialPhoneNo(field) {
    if (field != null && field != undefined && field.length > 8 ){
      var phoneNumDigits = field.replace(/\D/g, '');      
      var formattedNumber = phoneNumDigits;
      if (phoneNumDigits.length >= 10){
        formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6,10);
      }
        else if (phoneNumDigits.length >= 6){
        formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6);
      }else if (phoneNumDigits.length >= 3){
          formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3);
      }
      return formattedNumber;
    }else{
      return field;
    }
    }

  refreshFamilyList(data){
   /*  this.service.getFamilyList().subscribe(data=>{
       this.FamilyList=data;
      },error => {
        console.log(error)
      }); */
      this.members = true;
      var state = window.history.state
      if (state.accesstoken){
        this.service.membersfamilylist(state.accesstoken)
        .subscribe(
          data => {
            this.nomemberselected = false;
            this.memberFamily=data['memberfamily'];
          },
          error => console.log(error));
      }else {
        this.service.membersfamilylist(data)
        .subscribe(
          data => {
            if (data['memberfamily'].length > 0){
              this.nomemberselected = false;
              this.memberFamily=data['memberfamily'];
            }
            else{
              this.nomemberselected = true;
            }
          },
          error => console.log(error));
      }
      setInterval(() => { this.ref.detectChanges(); }, 0);

  }  
  refresh(){
    setInterval(() => { this.ref.detectChanges(); }, 0);
  }


    deletespouse(spouse){
      this.service.deleteMemberFamily(spouse.MemberId,{'name':"spouse"}).subscribe(data => {
        this.notifyService.showSuccess("Deleted SuccessFull","Member Updated")
        this.refresh();
      });
    }
    deletedep1(dep1){
      this.service.deleteMemberFamily(dep1.MemberId,{'name':"dep1"}).subscribe(data => {
        this.notifyService.showSuccess("Deleted SuccessFull","Member Updated")
        this.refresh();
      });
    }
    deletedep2(dep2){
      this.service.deleteMemberFamily(dep2.MemberId,{'name':"dep2"}).subscribe(data => {
        this.notifyService.showSuccess("Deleted SuccessFull","Member Updated")
        this.refresh();
      });
    }
    deletedep3(dep3){
      this.service.deleteMemberFamily(dep3.MemberId,{'name':"dep3"}).subscribe(data => {
        this.notifyService.showSuccess("Deleted SuccessFull","Member Updated")
        this.refresh();
      });
    }
    deletedep4(dep4){
      this.service.deleteMemberFamily(dep4.MemberId,{'name':"dep4"}).subscribe(data => {
        this.notifyService.showSuccess("Deleted SuccessFull","Member Updated")
        this.refresh();
      });
    }
    deletedep5(dep5){
      this.service.deleteMemberFamily(dep5.MemberId,{'name':"dep5"}).subscribe(data => {
        this.notifyService.showSuccess("Deleted SuccessFull","Member Updated")
        this.refresh();
      });
    }
    deletedep6(dep6){
      this.service.deleteMemberFamily(dep6.MemberId,{'name':"dep6"}).subscribe(data => {
        this.notifyService.showSuccess("Deleted SuccessFull","Member Updated")
        this.refresh();
      });
    }

    getrouter(){
      this.activatedRoute.params.subscribe(params => {
        const email = params['email'];
        const member_id = params['member_id'];
        const dependent = params['dependent'];
        const transaction_id = params['transaction_id'];
        const amount = params['amount'];
        this.email = email;
        this.member_id = member_id;
        this.dependent = dependent;
        this.trans = transaction_id;
        this.amount = amount;
      });
    }
    backClicked() {
      this._location.back();
    }
     /*
  idMemberFamilyList(data){
    console.log("data id",data)
    this.service.membersfamilylist(data)
    .subscribe(
      data => {
        this.members = true;
        this.memberFamily=data['memberfamily'];
        console.log(data);
      },
      error => console.log(error));
    
    }

   
  idrefreshFamilyList(data){
     this.service.getFamilyListPerID(data).subscribe(data=>{
       this.FamilyLists=data;
      },error => {
        console.log(error)
      });
    }
    // updateActive(isActive: boolean) {
    //   this.customerService.updateCustomer(this.customer.id,
    //     { name: this.customer.name, age: this.customer.age, active: isActive })
    //     .subscribe(
    //       data => {
    //         console.log(data);
    //         this.customer = data as Customer;
    //       },
    //       error => console.log(error));
    // }
    
     updateFamily(data){
      //this.select= true;
      var FamilyName = (<HTMLInputElement>document.getElementById("FamilyName")).value;
      //console.log("Famlily",FamilyName)
      this.service.updateFamily(data,{FamilyName:FamilyName})
            .subscribe(
              data => {
                console.log(data);
                this.notifyService.showSuccess(data,"Family Name")
                this.select= false;
                this.refreshFamilyList();
               // this.route.navigate(['']); // navigate to other page
              },
              error => console.log(error));
     // this.idrefreshFamilyList();
      //console.log("data",data)
    }  
    updateIDFamily(data){
      this.select= true;
      this.idrefreshFamilyList(data);
      console.log("data",data)
    }
    deleteFamily(data) {
      console.log("delete",data)
      this.service.deleteFamily(data).subscribe(
          data => {
            console.log(data);
            this.notifyService.showWarning("Deleted Record successfully !!", "Delete")
            this.refreshFamilyList();
          },
          error => console.log(error));
    }
    */
  }



    /* To Use JQUERY In angular  */
      //   $(document).ready(function() {
      //     console.log("Hello");
      // });
