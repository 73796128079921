import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { SharedService} from "src/app/shared.service"
import {Router} from '@angular/router';
import {Location} from '@angular/common';


@Component({
  selector: 'app-checkout-renew',
  templateUrl: './checkout-renew.component.html',
  styleUrls: ['./../../modules/user-profile/profile-overview/profile.css'],
})
export class CheckoutRenewComponent implements OnInit {
  email: string;
  trans: string;
  dependent;
  membershipplan: string;
  amount;
  member_id;
  curDate=new Date();
  datae;
  constructor(private activatedRoute: ActivatedRoute,private ref: ChangeDetectorRef, private _location: Location,private service:SharedService ) { }

  ngOnInit(): void {
    this.getrouter();
    var data = { "email" : this.email ,"transactionID" : this.trans ,"dependent" : this.dependent , "productName" : this.membershipplan , "price":this.amount , "description":"renew payment","status" : "payment done","paymentType": "Member"}
    this.service.payment(data).subscribe(data=> {
    })
    this.service.renewpaymentget(this.member_id).subscribe(data=> {
    })
    setInterval(() => { this.ref.detectChanges(); }, 0);
  }

  backClicked() {
    this._location.back();
  }
   
  getrouter(){
    this.activatedRoute.params.subscribe(params => {
      const email = params['email'];
      const membershipplan = params['membershipplan'];
      const dependent = params['dependent'];
      const transaction_id = params['transaction_id'];
      const amount = params['amount'];
      const member_id = params['member_id'];
      this.email = email;
      this.membershipplan = membershipplan;
      this.dependent = dependent;
      this.trans = transaction_id;
      this.amount = amount;
      this.member_id = member_id;
    });
  }


}
