import { Component ,ChangeDetectorRef,OnInit ,ViewChild , ElementRef } from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import {  FormBuilder } from '@angular/forms';    
import { NotificationService } from './../../notification.service'
import {Router} from '@angular/router';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { environment } from 'src/environments/environment';
import * as $ from 'jquery'
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {
  map,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-show-member',
  templateUrl: './show-member.component.html',
  styleUrls: ['./show-member.component.css']
})
export class ShowMemberComponent implements OnInit {
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  constructor(private modalService: NgbModal , private activatedRoute: ActivatedRoute,private service:SharedService ,public snackBar: MatSnackBar,private ref: ChangeDetectorRef, private formbulider: FormBuilder,private notifyService : NotificationService,private route:Router , private toastr: ToastrService) { }
  status= false;
  MembersList:any=[]; 
  filteredMembersList:any=[]; 
  // familyList:any=[]; 
  page = 1;
  count = 0;
  tableSize = 10;
  tableSizes = [10,20,50,100];
  APIUrl;
  UserForm: any;  
  loader = false;  
  selectedFile: File
  selectedMailAttachmentFile: File
  selectedMailAttachmentFile1: File
  selectedMailAttachmentFile2: File
  imageSrc: string;
  attachMailAttachmentimageSrc: string;
  updatemember = false;
  isValidFlg;
  searchText;
  ghsadminlogin = false;
  blankdata = true;
  state:any=[];
  display = "none";
  compose_to;
  compose_cc;
  compose_bcc;
  editor_subject;
  editor_value;
  showccFiller = false;
  showbccFiller = false;
  requests;
  emailsuggestions;
  initialprefix = "";
  statusSearch = "";
  activeSearch = "true";
  giftsearch = "";
  giftStatus = false;
  notes = "";
  userInfo;
  userType;
  memberplanList;
  eventdetails;
  search1 = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term === '' ? []
      : this.emailsuggestions.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  );

ngOnInit(): void {
    this.APIUrl = this.service.APIUrl;
    this.status=true;
    this.activatedRoute.params.subscribe(params => {
      const cid = params['prefix'];
      if (cid != "" && cid != undefined){
        this.initialprefix = cid
      }
    });
    this.refreshMemberList();
    this.service.getMemberShipPlanList().subscribe(data => {
      this.memberplanList = data;
    });
    
    this.service.getcurrentEventPhoto().subscribe(data=>{
      if (data.length > 0){
        this.eventdetails = data[0];
      }
     });
    setInterval(() => { this.ref.detectChanges(); }, 0);
  }

  openGiftDialog(context, element) {
    if (element.giftrecieved == 'received'){
      this.giftStatus = true
    }else{
      this.giftStatus = false
    }
    this.userInfo = element;
    this.modalService.open(context, { modalDialogClass: 'dark-modal', ariaLabelledBy: 'modal-title', centered: true, scrollable: true });
  }

  openNotesDialog(context, element) {
    this.notes = element.notes
    this.userInfo = element;
    this.modalService.open(context, { modalDialogClass: 'dark-modal', ariaLabelledBy: 'modal-title', centered: true, scrollable: true });
  }

  updategiftnote(type){   
    var data = {}
    if (type == 'notes'){
      data['notes'] = this.notes
    }else{
      data['giftrecieved'] = this.giftStatus === true ? 'received' : '';
    }
    this.MembersList = this.MembersList.map(item => {
      if (item['MemberId'] === this.userInfo.MemberId) {
        if (type == 'notes'){
          item['notes'] = data['notes'];
        }else{
          item['giftrecieved'] = data['giftrecieved'];
        }
      }
      return item;
    });
    this.filteredMembersList = this.MembersList;
    
    this.ref.detectChanges();
    this.closeDialog();
    this.service.updateMemberLimited(this.userInfo.MemberId,data).subscribe(data => {
    });
  }   
  
  closeDialog() {
    this.modalService.dismissAll();
  }

  changeprefix(): void {
    this.loader = true;
    this.loader = true;
    const requestData = {
      active: this.activeSearch,  // should be "true" or "false"
      gift: this.giftsearch,       // should be "true" or "false"
      membertype: this.statusSearch || null // e.g., 'regular', 'premium', etc.
    };
    this.service.listnewMember(requestData).subscribe(data=>{
      this.loader = false;
      this.MembersList = data['member'];
      this.filteredMembersList=data['member'];
      this.ref.detectChanges();
    },error => {
      this.loader = false;
    });
    // return;
    // this.searchText = '';
    // var results = this.filteredMembersList; // Start with the full list
    // // Filter by Membership Type
    // if (this.statusSearch) {
    //   results = results.filter(item => item.prefix === this.statusSearch);
    // }  
    // // Filter by Active/Inactive Status
    // if (this.activeSearch !== '') {
    //   const isActive = this.activeSearch === 'true'; // Convert string to boolean
    //   results = results.filter(item => item.is_active === isActive);
    // }
    // if (this.giftsearch !== '') {
    //   if (this.giftsearch == 'true'){
    //     results = results.filter(item => item.giftrecieved == 'received');
    //   }else{
    //     results = results.filter(item => item.giftrecieved != 'received');
    //   }
    // }
    // // Update the displayed list
    // this.MembersList = results;
  }
    // onRowClick(event, id){
    //   console.log(event.target.outerText, id);
    // }
    // search(){
    //   if (this.searchText == ""){
    //     this.blankdata = true;
    //   }else if (this.searchText.toLowerCase() == "all"){
    //     this.blankdata = false;
    //   } else {
    //     this.blankdata = false;
    //   }
    // }
    search(): void {
      this.statusSearch = "";
      // this.activeSearch = "";
      this.giftsearch = "";
      if (this.searchText === '') {
        this.MembersList = this.filteredMembersList; // Reset to full list if search text is empty
      } else {
        this.MembersList = this.filteredMembersList.filter(item => 
          item.FamilyPlanId.toLowerCase().includes(this.searchText.toLowerCase()) ||
          item.MemberFirstName.toLowerCase().includes(this.searchText.toLowerCase()) ||
          item.MemberLastName.toLowerCase().includes(this.searchText.toLowerCase()) ||
          item.email.toLowerCase().includes(this.searchText.toLowerCase()) ||
          item.street.toLowerCase().includes(this.searchText.toLowerCase())
        );
      }
      this.ref.detectChanges();
    }

    onRowClick(event) {
        // console.log("event",event);
        this.APIUrl = this.service.APIUrl;
        this.UserForm = this.formbulider.group({    
          MemberFirstName: [event.MemberFirstName || ""],    
          MemberLastName: [event.MemberLastName || ""],    
          email: [event.email || ""],    
          contactPhone: [event.contactPhone || ""], 
          //For 10 digits validation use below validator
          // contactPhone: [event.,[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],    
          // FamilyId: [event.FamilyId],    
          street: [event.street || ""], 
          zipcode: [event.zipcode || ""], 
          city: [event.city], 
          state: [event.state], 
          // county: [event.county], 
          memberImage: [event.memberImage], 
          MemberId:[event.MemberId]
        });
        this.updatemember =true;
        var id = (<HTMLInputElement>document.getElementById("listhere"));
        id.scrollIntoView({behavior: 'smooth'});
    }

    deletePic() {
      // this.formGroup.value.pic = '';
      this.imageSrc = '';
      this.selectedFile = null;
    }
    
    refreshMemberList(){
      const authDataMem = JSON.parse(localStorage.getItem(this.authLocalStorageToken+"mem"));
      if (authDataMem){
        this.userType = 'member';
        this.ghsadminlogin = false;
        this.service.getMembersListIndividual(authDataMem).subscribe(data=>{
            this.blankdata = false;
            var arr=[];  // to display states use this code
            for (var i in data['state']){ arr.push(data['state'][i]) }
            this.state = arr;  
            this.MembersList=data['member'];
            this.filteredMembersList=data['member'];
        });
      }
      else{
        this.userType = 'admin'
        this.ghsadminlogin = true;
        if (this.initialprefix != ""){
          this.statusSearch = this.initialprefix;
          this.activeSearch = 'true';
          this.blankdata = false;
          this.changeprefix();
          const authDataGA = JSON.parse(localStorage.getItem(this.authLocalStorageToken+"ga"));          
            if (authDataGA){
              this.service.accTokengsh(authDataGA).subscribe(data => {
                if (data['is_staff'] == true){
                  this.userType = 'tempadmin';
                }
              });
            }
          // this.MembersList = this.filteredMembersList.filter(item => item.prefix === this.initialprefix);    
        }else{
          this.service.getMembersList().subscribe(data=>{
            const authDataGA = JSON.parse(localStorage.getItem(this.authLocalStorageToken+"ga"));          
            if (authDataGA){
              this.service.accTokengsh(authDataGA).subscribe(data => {
                // console.log("gsh admin topbar")
                if (data['is_staff'] == true){
                  this.userType = 'tempadmin';
                  // this.ghsadminlogin = false;
                }
              });
            }
            this.MembersList=data['member'];
            this.filteredMembersList=data['member'];
            // this.familyList=data['family'];
            var arr=[];  // to display states use this code
            for (var i in data['state']){ arr.push(data['state'][i]) }
            var arremail=[];
            for (var i in data['member']){ 
              var email = data['member'][i]['email']
              arremail.push(email)
            }
            // this.emailsuggestions = arremail;
            var realColors = arremail.filter(function (e) { return e != null;});
            this.emailsuggestions = realColors
            this.state = arr;        
          },error => {
            console.log(error)
          });
        }
      }
    }
    onTableDataChange(event){
      this.page = event;
      // this.refreshMemberList();
    }  
    
    onTableSizeChange(event): void {
      this.tableSize = event.target.value;
      this.page = 1;
      // this.refreshMemberList();
    }  
    onFileChange(event) {
      const reader = new FileReader();
      this.selectedFile = event.target.files[0]
  
      if(event.target.files && event.target.files.length) {
        const [file] = event.target.files;
        reader.readAsDataURL(file);
      
        reader.onload = () => {
             this.imageSrc = reader.result as string;    
     };
     }
    }

    onFileMailAttachmentChange(event) {
      var filePath = $("#spnFilePath");
      $("#spnFilePath").empty();
      const maxAllowedSize = 10 * 1024 * 1024;
      if (event.target.files.length > 3) {
        Swal.fire({  
          text: "File Upload Limit Upto 3",
          icon: "warning",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          timer: 5000,
          customClass: {
            confirmButton: "btn font-weight-bold btn-light-warning"
          },
        });
        this.notifyService.showWarning("File Upload Limit Upto 3", "File Upload Limit Exceed")
        return ;
    }
      if (event.target.files[0].size > maxAllowedSize) {
        // console.log("file size limit exceeded")
        this.snackBar.open("Error","File Size Cannot Exceed More Than 10 MB.", {
          duration: 5000,
          verticalPosition: 'top'
        });
        return ;
      }
      var files = event.target.files;
          for (let i = 0; i < files.length; i++) {
            filePath.append('<br><span class="flaticon2-clip-symbol text-warning icon-1x mr-2"><b>&nbsp;' + files[i].name + " </b></span>");
            this.selectedMailAttachmentFile = files[0] 
            this.selectedMailAttachmentFile1 = files[1] 
            this.selectedMailAttachmentFile2 = files[2] 
        }
    }

    openModal(data) {
      this.compose_to = data.email
      this.display = "block";
    }

    onCloseHandled() {
      this.display = "none";
    }
    
    send(){
      const uploadData = new FormData();
    
      // console.log("value submitted successfully")
      // console.log("value submitted compose_to",this.compose_to)
      // console.log("value submitted editor_subject",this.editor_subject)
      // console.log("value submitted editor_value",this.editor_value)
      const authDataGA = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken+"ga")
      );
      if(this.selectedMailAttachmentFile){
        uploadData.append('file', this.selectedMailAttachmentFile ,this.selectedMailAttachmentFile.name || "");
      }
      if(this.selectedMailAttachmentFile1){
      uploadData.append('file', this.selectedMailAttachmentFile1 , this.selectedMailAttachmentFile1.name || "");
      }
      if(this.selectedMailAttachmentFile2){
        uploadData.append('file', this.selectedMailAttachmentFile2 , this.selectedMailAttachmentFile2.name || "");
      }
      uploadData.append('compose_to', this.compose_to || "");
      uploadData.append('compose_cc', this.compose_cc || "");
      uploadData.append('compose_bcc', this.compose_bcc || "");
      uploadData.append('editor_subject', this.editor_subject || "");
      uploadData.append('editor_value', this.editor_value || "");
      uploadData.append('access_token', authDataGA || "");
      this.display = "none";
      var data = { "compose_to" : this.compose_to ,"compose_cc" : this.compose_cc ,"compose_bcc" : this.compose_bcc , "editor_subject" : this.editor_subject , "editor_value" : this.editor_value ,"access_token": authDataGA}
      this.service.memmail(uploadData).subscribe(data=> {
        setTimeout(() => {
          this.toastr.info("Email Sending", "Email",{
            timeOut: 5000,
            progressBar:true,
            progressAnimation:"increasing",
          });
          setTimeout(() => {
            Swal.fire({  
              text: 'Email Sent Successfully',
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              timer: 3000,
              customClass: {
                confirmButton: "btn font-weight-bold btn-light-success"
              },
            });
            this.notifyService.showSuccess("Email Sent Successfully !! "+this.compose_to, "Email")
          },5000);
        }, 0);
      })
      this.compose_cc = "";
      this.compose_bcc = "";
      this.editor_subject = "";
      this.editor_value="";
      $("#spnFilePath").empty();
      $("#id_inputfiles").val("");
    }

    attachmentfiles(){
      $("#id_inputfiles").click()
    }

    deleteMember(data) {
      // console.log("delete",data)
      this.service.deleteMember(data).subscribe(
          data => {
            // console.log(data);
            this.notifyService.showWarning("Delete Successfully !!", "Delete")
          },
          error => console.log(error));
    }
  
    cancel(){
      this.updatemember = false;

    }
    validateinitialPhoneNo(field) {
      if (field != null && field != undefined && field.length > 8 ){
        var phoneNumDigits = field.replace(/\D/g, '');      
        var formattedNumber = phoneNumDigits;
        if (phoneNumDigits.length >= 10){
          formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6,10);
        }
          else if (phoneNumDigits.length >= 6){
          formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6);
        }else if (phoneNumDigits.length >= 3){
            formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3);
        }
        return formattedNumber;
      }else{
        return field;
      }
      }
    validatePhoneNo(field) {
      var phoneNumDigits = field.value.replace(/\D/g, '');
      
      this.isValidFlg = (phoneNumDigits.length >= 10);
    
      var formattedNumber = phoneNumDigits;
      if (phoneNumDigits.length >= 10){
        formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6,10);
      }
        else if (phoneNumDigits.length >= 6){
        formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6);
      }else if (phoneNumDigits.length >= 3){
          formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3);
      }
    
      field.value = formattedNumber;
      }

    public openPDF():void {
          let DATA = document.getElementById('users');
          html2canvas(DATA).then(canvas => {
              
              let fileWidth = 208;
              let fileHeight = canvas.height * fileWidth / canvas.width;
              
              const FILEURI = canvas.toDataURL('image/png')
              let PDF = new jsPDF('p', 'mm', 'a4');
              let position = 0;
              PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)
              
              PDF.save('member.pdf');
          });     
        }
    onFormSubmit()  {  
      var phoneNumDigits = this.UserForm.value.contactPhone.replace(/\D/g, '');
      var final = phoneNumDigits.slice(0, 10);
      var formattedNumber = '(' + final.substring(0, 3) + ') ' + final.substring(3, 6) + '-' + final.substring(6,10);
       
      const uploadData = new FormData();
      if (this.selectedFile){
        uploadData.append('memberImage', this.selectedFile ,this.selectedFile.name);
      }
      const authDataGA = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken+"ga")
      );
      if (authDataGA){
        uploadData.append('user_token', authDataGA);
      }
      uploadData.append('MemberFirstName', this.UserForm.value.MemberFirstName);
      uploadData.append('MemberLastName', this.UserForm.value.MemberLastName);
      uploadData.append('email', this.UserForm.value.email);
      uploadData.append('contactPhone', formattedNumber);
      // uploadData.append('FamilyId', this.UserForm.value.FamilyId || "");
      uploadData.append('street', this.UserForm.value.street);
      uploadData.append('zipcode', this.UserForm.value.zipcode);
      uploadData.append('city', this.UserForm.value.city);
      uploadData.append('state', this.UserForm.value.state);
      // uploadData.append('county', this.UserForm.value.county);
      // console.log("user",user)
      // var upload = { "uploadData" : uploadData}
      this.service.updateMember(this.UserForm.value.MemberId,uploadData)
          .subscribe(
            data => {
              if (data['error']){
                this.notifyService.showWarning(data['error'],"Error")
              }else{
                this.notifyService.showSuccess(data,"Member Updated")
                this.updatemember = false;
                this.refreshMemberList();
              }
              // console.log("update member",data);
              this.ref.detach();
              setInterval(() => { this.ref.detectChanges(); }, 0);
            },
            error => console.log(error));
          }    
        }
        
// window.location.reload();

/// code for pdf conversion 
//  npm install jspdf

// import { Component, ViewChild , ElementRef ,OnInit } from '@angular/core';
// before constructor
  // @ViewChild('htmlData') htmlData:ElementRef;

//   import jsPDF from 'jspdf';
//   import html2canvas from 'html2canvas';
//   public openPDF():void {
//     let DATA = document.getElementById('users');
      
//     html2canvas(DATA).then(canvas => {
        
//         let fileWidth = 208;
//         let fileHeight = canvas.height * fileWidth / canvas.width;
        
//         const FILEURI = canvas.toDataURL('image/png')
//         let PDF = new jsPDF('p', 'mm', 'a4');
//         let position = 0;
//         PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight)
        
//         PDF.save('angular-demo.pdf');
//     });     
//   }
//   <div class="col-md-4 text-right">
//   <button class="btn btn-success btn-block" (click)="openPDF()">Download PDF</button>
// </div>