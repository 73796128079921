import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'blank'
})
export class BlankPipe implements PipeTransform {

  // transform(value: unknown, ...args: unknown[]): unknown {
  //   return null;
  // }
//   transform(items: any[], args: any[]): any {
//     return items.filter(item => item.id.indexOf(args[0]) !== -1);
// }
transform(value: any, args?: any): any {

  if(!value)return null;
  if(!args)return value;
  args = args.toLowerCase();
  if (args == "all") return value;

  return value.filter(function(item){
    if (item.MemberFirstName){
      return JSON.stringify(item).toLowerCase().includes(args) || JSON.stringify((item.MemberFirstName.toLowerCase() +" "+ item.MemberLastName.toLowerCase())).toLowerCase().includes(args);
    }else{
      return JSON.stringify(item).toLowerCase().includes(args)
    }
  });
}
}
