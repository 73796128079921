import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { SharedService } from "./../../shared.service"
import { NotificationService } from './../../notification.service'

@Component({
  selector: 'app-membership-plan',
  templateUrl: './membership-plan.component.html',
  styleUrls: ['./membership-plan.component.css']
})
export class MembershipPlanComponent implements OnInit {

  constructor(private service: SharedService, private ref: ChangeDetectorRef, private notifyService: NotificationService) { }
  plan = false;
  isedit = false;
  isuser = false;
  validstart;
  validend;
  darpanprice;
  viewdarpan = false;
  // active;
  userObj = {
    MembershipType: '', prefix: '', haveSpouse: '', PlanFee: '', maxDependent: '', predependentFee: '',
    serviceCharge: '', validityType: '', is_active: '',
  }
  memberplanList: any[];
  ngOnInit(): void {
    this.refresh();
    setInterval(() => { this.ref.detectChanges(); }, 0);

  }
  refresh() {
    this.service.getMemberShipPlanList().subscribe(data => {
      this.memberplanList = data;
      var darpan = this.memberplanList.filter(item => item.prefix == "DA")
      if (darpan.length > 0) {
        this.darpanprice = darpan[0]['PlanType']
      }
    })
  }
  editUser(data) {

  }

  updateDarpanPrice() {
    var darpan = this.memberplanList.filter(item => item.prefix == "DA")
    if (darpan.length > 0) {
      darpan[0]['PlanType'] = this.darpanprice
      this.service.updateMemberShipPlan(darpan[0].id, darpan[0])
        .subscribe(
          data => {
            this.viewdarpan = false;
            this.refresh();
            this.notifyService.showSuccess(data, "MemberShip Updated")
          },
          error => console.log(error));
    }
  }

  updateMemberShip(data) {
    if (data.maxDependent > 6) {
      this.notifyService.showWarning("Attention", "A maximum of 6 dependents is allowed.");
      return;
    }
    if (data.maxDependent < 1) {
      this.notifyService.showWarning("Attention", "At least 1 dependent is required.");
      return;
    }
    //this.select= true;
    //var FamilyName = (<HTMLInputElement>document.getElementById("FamilyName")).value;
    // console.log(data);
    if (this.validstart) { data.validityFrom = this.validstart }
    if (this.validend) { data.validityTo = this.validend }
    this.service.updateMemberShipPlan(data.id, data)
      .subscribe(
        data => {
          this.refresh();
          this.notifyService.showSuccess(data, "MemberShip Updated")
        },
        error => console.log(error));

  }


  onChangeEvent6(data) {
    this.validstart = new Date(data.value).toISOString().split("T")[0]
  }
  onChangeEvent(data) {
    this.validend = new Date(data.value).toISOString().split("T")[0]
  }
  viewUser(user: any) {
    this.isuser = true;
    this.userObj = user;
    //this.isedit=true;
    var id = (<HTMLInputElement>document.getElementById("listhere"));
    id.scrollIntoView({ behavior: 'smooth' });
    // var planFee = (<HTMLInputElement>document.getElementById("planFee"));
    // planFee.contentEditable = "true";
    // this.plan=true
  }

}
