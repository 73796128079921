import { Component, AfterViewInit ,ViewChild } from '@angular/core';
import {SharedService} from 'src/app/shared.service';
import { environment } from 'src/environments/environment';

import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';


@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements AfterViewInit {
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  displayedColumns: string[] = ['user', 'email', 'userType' ,'ip_address','method_type','logger_message','created_at'];
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  data;
  constructor(private service:SharedService) { }
  
  ngAfterViewInit() {
    const authDataGA = JSON.parse(
      localStorage.getItem(this.authLocalStorageToken+"ga")
    );
    const authDataSU = JSON.parse(
      localStorage.getItem(this.authLocalStorageToken+"su")
    );
    if (authDataGA || authDataSU){
      this.service.logslist().subscribe(data=>{
        this.data = new MatTableDataSource(data);
        this.data.sort = this.sort;
        this.data.paginator = this.paginator;
  
     },error => {
       console.log(error)
     });
    }
  }

  public doFilter = (value: string) => {
    this.data.filter = value.trim().toLocaleLowerCase();
  }
  }



