import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { SharedService} from "src/app/shared.service"
import {Router} from '@angular/router';
import {Location} from '@angular/common';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./../../modules/user-profile/profile-overview/profile.css'],
})
export class CheckoutComponent implements OnInit {
  email: string;
  trans: string;
  dependent;
  membershipplan: string;
  amount;
  curDate=new Date();
  datae;
  constructor(private activatedRoute: ActivatedRoute,private ref: ChangeDetectorRef, private _location: Location,private service:SharedService ) { }

  ngOnInit(): void {
    this.getrouter();
    var data = { "email" : this.email ,"transactionID" : this.trans ,"dependent" : this.dependent , "productName" : this.membershipplan , "price":this.amount , "description":"initial payment"}
    this.service.payment(data).subscribe(data=> {
      // console.log("data payment",data)
    })
    this.service.paymentget(this.trans).subscribe(data=> {
      this.datae = data['created_at']
      setInterval(() => { this.ref.detectChanges(); }, 0);
    })
  }

  backClicked() {
    this._location.back();
  }
   
  getrouter(){
    this.activatedRoute.params.subscribe(params => {
      const email = params['email'];
      const membershipplan = params['membershipplan'];
      const dependent = params['dependent'];
      const transaction_id = params['transaction_id'];
      const amount = params['amount'];
      this.email = email;
      this.membershipplan = membershipplan;
      this.dependent = dependent;
      this.trans = transaction_id;
      this.amount = amount;
      // console.log("email",email);
      // console.log("membershipplan",membershipplan);
      // console.log("dependent",dependent);
      // console.log("transaction_id",transaction_id);
      // return userId;
    });
  }


}
