<!-- <div class="page-title">
    <div class="container">
        <div class="inner">
            <div class="column">
                <div class="title">
                    <h1>Leadership</h1>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- <div class="container">
    <div class="mt-4 pt-2">
        <div class="row team-list sort-destination" data-sort-id="team">
            <div class="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span class="thumb-info thumb-info-hide-wrapper-bg mb-4">
                    <span class="thumb-info-wrapper">
                        <a href="javascript:;"><img class="img-fluid"
                                src="https://gshouston.org/upload/leadership/profile-main1534869396-585X585.jpg"
                                alt="Geeta Patel"><span class="thumb-info-title"> <span class="thumb-info-inner">Geeta
                                    Patel </span>
                                <span class="thumb-info-type"> President</span> </span>
                        </a>
                    </span>
                    <span class="thumb-info-caption">
                        <span class="thumb-info-caption-text"><br>
                            <i class="fa fa-phone-square"></i>&nbsp; <strong>Phone: </strong><a
                                href="tel:281-615-2319"> 281-615-2319 </a>
                            <br> <i class="fa fa-envelope"></i>&nbsp; <strong>Email: </strong>
                            <a href="mailto:agla.geeta@gmail.com"> agla.geeta@gmail.com </a>
                        </span>
                    </span>
                </span>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span class="thumb-info thumb-info-hide-wrapper-bg mb-4">
                    <span class="thumb-info-wrapper">
                        <a href="javascript:;"><img class="img-fluid"
                                src="https://gshouston.org/upload/leadership/profile-main1534869407-585X585.jpg"
                                alt="Kalpesh Rana"><span class="thumb-info-title"> <span
                                    class="thumb-info-inner">Kalpesh Rana </span>
                                <span class="thumb-info-type">Vice President</span> </span>
                        </a>
                    </span>
                    <span class="thumb-info-caption">
                        <span class="thumb-info-caption-text"><br>
                            <i class="fa fa-phone-square"></i> &nbsp;<strong>Phone: </strong><a
                                href="tel:832-633-4009">832-633-4009</a>
                            <br> <i class="fa fa-envelope"></i> &nbsp;<strong>Email: </strong>
                            <a href="mailto:kal@lincolnprinting.net">kal@lincolnprinting.net</a>
                        </span>
                    </span>
                </span>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span class="thumb-info thumb-info-hide-wrapper-bg mb-4">
                    <span class="thumb-info-wrapper">
                        <a href="javascript:;"><img class="img-fluid"
                                src="https://gshouston.org/upload/leadership/profile-main1569791375-585X585.jpg"
                                alt="Bharat Parikh"><span class="thumb-info-title"> <span
                                    class="thumb-info-inner">Bharat Parikh </span>
                                <span class="thumb-info-type">Secretary</span> </span>
                        </a>
                    </span>
                    <span class="thumb-info-caption">
                        <span class="thumb-info-caption-text"><br>
                            <i class="fa fa-phone-square"></i> &nbsp;<strong>Phone: </strong><a
                                href="tel:972-998-1792">972-998-1792</a>
                            <br> <i class="fa fa-envelope"></i> &nbsp;<strong>Email: </strong>
                            <a href="mailto:bharatparikh@msn.com">bharatparikh@msn.com</a>
                        </span>
                    </span>
                </span>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span class="thumb-info thumb-info-hide-wrapper-bg mb-4">
                    <span class="thumb-info-wrapper">
                        <a href="javascript:;"><img class="img-fluid"
                                src="https://gshouston.org/upload/leadership/profile-main1534869427-585X585.jpg"
                                alt="Jignesh Patel"><span class="thumb-info-title"> <span
                                    class="thumb-info-inner">Jignesh Patel </span>
                                <span class="thumb-info-type">Joint Secretary</span> </span>
                        </a>
                    </span>
                    <span class="thumb-info-caption">
                        <span class="thumb-info-caption-text"><br>
                            <i class="fa fa-phone-square"></i>&nbsp; <strong>Phone: </strong><a
                                href="tel:281-507-3015">281-507-3015</a>
                            <br> <i class="fa fa-envelope"></i>&nbsp; <strong>Email: </strong>
                            <a href="mailto:jpatel7866@gmail.com">jpatel7866@gmail.com</a>
                        </span>
                    </span>
                </span>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span class="thumb-info thumb-info-hide-wrapper-bg mb-4">
                    <span class="thumb-info-wrapper">
                        <a href="javascript:;"><img class="img-fluid"
                                src="https://gshouston.org/upload/leadership/profile-main1569772155-585X585.jpg"
                                alt="Bharat Patel"><span class="thumb-info-title"> <span class="thumb-info-inner">Bharat
                                    Patel </span>
                                <span class="thumb-info-type">Treasurer</span> </span>
                        </a>
                    </span>
                    <span class="thumb-info-caption">
                        <span class="thumb-info-caption-text"><br>
                            <i class="fa fa-phone-square"></i>&nbsp; <strong>Phone: </strong><a
                                href="tel:713-459-4326">713-459-4326</a>
                            <br> <i class="fa fa-envelope"></i> &nbsp;<strong>Email: </strong>
                            <a href="mailto:bapatel3@gmail.com">bapatel3@gmail.com</a>
                        </span>
                    </span>
                </span>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span class="thumb-info thumb-info-hide-wrapper-bg mb-4">
                    <span class="thumb-info-wrapper">
                        <a href="javascript:;"><img class="img-fluid"
                                src="https://gshouston.org/upload/leadership/profile-main1534869447-585X585.jpg"
                                alt="Gopal Shah"><span class="thumb-info-title"> <span class="thumb-info-inner">Gopal
                                    Shah </span>
                                <span class="thumb-info-type">Joint Treasurer</span> </span>
                        </a>
                    </span>
                    <span class="thumb-info-caption">
                        <span class="thumb-info-caption-text"><br>
                            <i class="fa fa-phone-square"></i> &nbsp;<strong>Phone: </strong><a
                                href="tel:281-889-6434">281-889-6434</a>
                            <br> <i class="fa fa-envelope"></i> &nbsp;<strong>Email: </strong>
                            <a href="mailto:gopalshah001@yahoo.com">gopalshah001@yahoo.com</a>
                        </span>
                    </span>
                </span>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span class="thumb-info thumb-info-hide-wrapper-bg mb-4">
                    <span class="thumb-info-wrapper">
                        <a href="javascript:;"><img class="img-fluid"
                                src="https://gshouston.org/upload/leadership/profile-main1534869457-585X585.jpg"
                                alt="Darshak Thacker"><span class="thumb-info-title"> <span
                                    class="thumb-info-inner">Darshak Thacker </span>
                                <span class="thumb-info-type">Executive Committee</span> </span>
                        </a>
                    </span>
                    <span class="thumb-info-caption">
                        <span class="thumb-info-caption-text"><br>
                            <i class="fa fa-phone-square"></i> &nbsp;<strong>Phone: </strong><a
                                href="tel:713-456-0999">713-456-0999</a>
                            <br> <i class="fa fa-envelope"></i> &nbsp;<strong>Email: </strong>
                            <a href="mailto:darshak2011@yahoo.com">darshak2011@yahoo.com</a>
                        </span>
                    </span>
                </span>
            </div>
            <div class="col-12 col-sm-6 col-lg-3 isotope-item leadership">
                <span class="thumb-info thumb-info-hide-wrapper-bg mb-4">
                    <span class="thumb-info-wrapper">
                        <a href="javascript:;"><img class="img-fluid"
                                src="https://gshouston.org/upload/leadership/profile-main1534869467-585X585.jpg"
                                alt="Dr. Arun Banker"><span class="thumb-info-title"> <span class="thumb-info-inner">Dr.
                                    Arun Banker </span>
                                <span class="thumb-info-type">Executive Committee</span> </span>
                        </a>
                    </span>
                    <span class="thumb-info-caption">
                        <span class="thumb-info-caption-text"><br>
                            <i class="fa fa-phone-square"></i>&nbsp; <strong>Phone: </strong><a
                                href="tel:281-747-8435">281-747-8435</a>
                            <br> <i class="fa fa-envelope"></i> &nbsp;<strong>Email: </strong>
                            <a href="mailto:dr.arunbanker@gmail.com">dr.arunbanker@gmail.com</a>
                        </span>
                    </span>
                </span>
            </div>
        </div>
    </div>
</div> -->

<style type="text/css">

</style>
<section id="team" class="team-area">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="site-heading text-center">
                    <h2>Leadership<span></span></h2>
                    <h4></h4>
                </div>
            </div>
        </div>
        <div class="row team-items">
            <div class="col-md-3 single-item">
                <div class="item">
                    <div class="thumb">
                        <img class="img-fluid"
                            src="https://gshouston.org/upload/leadership/profile-main1534869396-585X585.jpg"
                            alt="Thumb">
                        <div class="overlay">
                            <p> <i class="fas fa-phone-alt"></i>&nbsp; <strong>Phone : </strong><a
                                    href="tel:281-615-2319"> 281-615-2319 </a>
                                <br>
                                <i class="fa fa-envelope"></i> &nbsp;<strong>Email : </strong>
                                <a href="mailto:agla.geeta@gmail.com"> agla.geeta@gmail.com </a></p>
                        </div>
                    </div>
                    <div class="info">
                        <span class="message">
                            <!-- <a href="#"><i class="fas fa-envelope-open"></i></a> -->
                        </span>
                        <h4>Geeta Patel</h4>
                        <span>President</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3 single-item">
                <div class="item">
                    <div class="thumb">
                        <img class="img-fluid"
                            src="https://gshouston.org/upload/leadership/profile-main1534869396-585X585.jpg"
                            alt="Thumb">
                        <div class="overlay">
                            <p> <i class="fas fa-phone-alt"></i>&nbsp; <strong>Phone : </strong><a
                                    href="tel:832-633-4009">832-633-4009</a>
                                <br>
                                <i class="fa fa-envelope"></i> &nbsp;<strong>Email : </strong>
                                <a href="mailto:kal@lincolnprinting.net">kal@lincolnprinting.net</a></p>
                        </div>
                    </div>
                    <div class="info">
                        <span class="message">
                            <!-- <a href="#"><i class="fas fa-envelope-open"></i></a> -->
                        </span>
                        <h4>Kalpesh Rana</h4>
                        <span>Vice President</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3 single-item">
                <div class="item">
                    <div class="thumb">
                        <img class="img-fluid"
                            src="https://gshouston.org/upload/leadership/profile-main1569791375-585X585.jpg"
                            alt="Thumb">
                        <div class="overlay">
                            <p> <i class="fas fa-phone-alt"></i>&nbsp; <strong>Phone : </strong><a
                                    href="tel:972-998-1792">972-998-1792</a>
                                <br>
                                <i class="fa fa-envelope"></i> &nbsp;<strong>Email : </strong>
                                <a href="mailto:bharatparikh@msn.com">bharatparikh@msn.com</a></p>
                        </div>
                    </div>
                    <div class="info">
                        <span class="message">
                            <!-- <a href="#"><i class="fas fa-envelope-open"></i></a> -->
                        </span>
                        <h4>Bharat Parikh</h4>
                        <span>Secretary</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3 single-item">
                <div class="item">
                    <div class="thumb">
                        <img class="img-fluid"
                            src="https://gshouston.org/upload/leadership/profile-main1534869396-585X585.jpg"
                            alt="Thumb">
                        <div class="overlay">
                            <p> <i class="fas fa-phone-alt"></i>&nbsp; <strong>Phone : </strong><a
                                    href="tel:281-507-3015">281-507-3015</a>
                                <br>
                                <i class="fa fa-envelope"></i> &nbsp;<strong>Email : </strong>
                                <a href="mailto:jpatel7866@gmail.com">jpatel7866@gmail.com</a></p>
                        </div>
                    </div>
                    <div class="info">
                        <span class="message">
                            <!-- <a href="#"><i class="fas fa-envelope-open"></i></a> -->
                        </span>
                        <h4>Jignesh Patel</h4>
                        <span>Joint Secretary</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row team-items">
            <div class="col-md-3 single-item">
                <div class="item">
                    <div class="thumb">
                        <img class="img-fluid"
                            src="https://gshouston.org/upload/leadership/profile-main1569772155-585X585.jpg"
                            alt="Thumb">
                        <div class="overlay">
                            <p> <i class="fas fa-phone-alt"></i>&nbsp; <strong>Phone : </strong><a
                                    href="tel:713-459-4326">713-459-4326</a>
                                <br> <i class="fa fa-envelope"></i> &nbsp;<strong>Email : </strong>
                                <a href="mailto:bapatel3@gmail.com">bapatel3@gmail.com</a></p>
                        </div>
                    </div>
                    <div class="info">
                        <span class="message">
                            <!-- <a href="#"><i class="fas fa-envelope-open"></i></a> -->
                        </span>
                        <h4>Bharat Patel</h4>
                        <span>Treasurer</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3 single-item">
                <div class="item">
                    <div class="thumb">
                        <img class="img-fluid"
                            src="https://gshouston.org/upload/leadership/profile-main1534869396-585X585.jpg"
                            alt="Thumb">
                        <div class="overlay">
                            <p> <i class="fas fa-phone-alt"></i>&nbsp; <strong>Phone : </strong><a
                                    href="tel:281-889-6434">281-889-6434</a>
                                <br>
                                <i class="fa fa-envelope"></i> &nbsp;<strong>Email : </strong>
                                <a href="mailto:gopalshah001@yahoo.com">gopalshah001@yahoo.com</a></p>
                        </div>
                    </div>
                    <div class="info">
                        <span class="message">
                            <!-- <a href="#"><i class="fas fa-envelope-open"></i></a> -->
                        </span>
                        <h4>Gopal Shah</h4>
                        <span>Joint Treasurer</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3 single-item">
                <div class="item">
                    <div class="thumb">
                        <img class="img-fluid"
                            src="https://gshouston.org/upload/leadership/profile-main1534869396-585X585.jpg"
                            alt="Thumb">
                        <div class="overlay">
                            <p> <i class="fas fa-phone-alt"></i>&nbsp; <strong>Phone : </strong><a
                                    href="tel:713-456-0999">713-456-0999</a>
                                <br>
                                <i class="fa fa-envelope"></i> &nbsp;<strong>Email : </strong>
                                <a href="mailto:darshak2011@yahoo.com">darshak2011@yahoo.com</a></p>
                        </div>
                    </div>
                    <div class="info">
                        <span class="message">
                            <!-- <a href="#"><i class="fas fa-envelope-open"></i></a> -->
                        </span>
                        <h4>Darshak Thacker</h4>
                        <span>Executive Committee</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3 single-item">
                <div class="item">
                    <div class="thumb">
                        <img class="img-fluid"
                            src="https://gshouston.org/upload/leadership/profile-main1534869396-585X585.jpg"
                            alt="Thumb">
                        <div class="overlay">
                            <p> <i class="fas fa-phone-alt"></i>&nbsp; <strong>Phone : </strong><a
                                    href="tel:281-747-8435">281-747-8435</a>
                                <br>
                                <i class="fa fa-envelope"></i> &nbsp;<strong>Email : </strong>
                                <a href="mailto:dr.arunbanker@gmail.com">dr.arunbanker@gmail.com</a></p>
                                <!-- <div class="social">
                                    <ul>
                                        <li class="twitter">
                                            <a href="#"><i class="fab fa-twitter"></i></a>
                                        </li>
                                        <li class="pinterest">
                                            <a href="#"><i class="fab fa-pinterest"></i></a>
                                        </li>
                                        <li class="instagram">
                                            <a href="#"><i class="fab fa-instagram"></i></a>
                                        </li>
                                        <li class="vimeo">
                                            <a href="#"><i class="fab fa-vimeo-v"></i></a>
                                        </li>
                                    </ul>
                                </div> -->
                        </div>
                    </div>
                    <div class="info">
                        <span class="message">
                            <!-- <a href="#"><i class="fas fa-envelope-open"></i></a> -->
                        </span>
                        <h4>Dr. Arun Banker</h4>
                        <span>Executive Committee</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- <div class="row team-items" *ngFor="let img of images; index as i">
    <div class="col-md-3 single-item">
        <div class="item">
            <div class="thumb">
                <img class="img-fluid" 
                    src="{{img.eventImage}}" 
                    alt="Thumb">
                <div class="overlay">
                    <p> <i class="fas fa-phone-alt"></i>&nbsp; <strong>Phone : </strong><a
                            href="tel:281-615-2319"> {{img.eventName}} </a>
                        <br>
                        <i class="fa fa-envelope"></i> &nbsp;<strong>Email : </strong>
                        <a href="mailto:agla.geeta@gmail.com"> {{i+1}} slide </a></p>
                </div>
            </div>
            <div class="info" routerLink="/viewevent/{{img.id}}">
                <span class="message">
                    <a><i class="fas fa-envelope-open"></i></a>
                </span>
                <h4 > {{img.eventName}} </h4>
                <span>View Details</span>
            </div>
        </div>
    </div>
</div>
// Ts file use this code...
import {SharedService} from 'src/app/shared.service';
  constructor(private service:SharedService) { }
  APIUrl;
  images: any[] = []; // array like this
ngOnInit(): void {
    this.service.getEventPhoto().subscribe(data=>{
      // console.log("data of event data",data);
      // this.imageObject = data
      this.APIUrl = this.service.APIUrl;

      for (let k in data) {
        if (data[k]['eventImage']){
          this.images.push({
            eventImage: this.APIUrl+data[k]['eventImage'],
            eventName: data[k]['eventName'],
            id: data[k]['id'],
          }) // Here add image url in array
        }
      }
     },error => {
       console.log(error)
     });
  } -->


  <!--
    part --2 used to seperate images in two part. single row two images. 
      <style>
    * {
      box-sizing: border-box;
    }
    
    body {
      margin: 0;
      font-family: Arial;
    }
    
    .header {
      text-align: center;
      padding: 32px;
    }
    
    .column {
      float: left;
      width: 50%;
      padding: 10px;
    }
    
    .column img {
      margin-top: 12px;
    }
    
    .row:after {
      content: "";
      display: table;
      clear: both;
    }
    </style>
    
    <div class="header">
      <h1>Image Grid</h1>
    </div>
    
    <section id="team" class="team-area">
    <div class="row team-items"> 
      <div class="column" *ngFor="let img of images; index as i">
        <div class="single-item">
            <div class="item">
                <div class="thumb">
                    <img class="img-fluid" 
                        src="{{img.eventImage}}" 
                        alt="Thumb">
                    <div class="overlay">
                        <p> <i class="fas fa-phone-alt"></i>&nbsp; <strong>Phone : </strong><a
                                href="tel:281-615-2319"> {{img.eventName}} </a>
                            <br>
                            <i class="fa fa-envelope"></i> &nbsp;<strong>Email : </strong>
                            <a href="mailto:agla.geeta@gmail.com"> {{i+1}} slide </a></p>
                    </div>
                </div>
                <div class="info" routerLink="/viewevent/{{img.id}}">
                    <span class="message">
                        <a><i class="fas fa-envelope-open"></i></a>
                    </span>
                    <h4 > {{img.eventName}} </h4>
                    <span>View Details</span>
                </div>
            </div>
        </div>
    </div>
    </div>
    </section>  -->