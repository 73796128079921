import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from "src/app/shared.service"
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-checkout-ticketNonmember',
  templateUrl: './checkout-ticketNonmember.component.html',
  styleUrls: ['./../../modules/user-profile/profile-overview/profile.css'],
})
export class CheckoutTicketNonmemberComponent implements OnInit {
  email: string;
  trans: string;
  eventDate: string;
  dependent;
  membershipplan: string;
  nonmemberid;
  curDate = new Date();
  datae = new Date();
  eventid;
  constructor(private activatedRoute: ActivatedRoute, private ref: ChangeDetectorRef, private _location: Location, private service: SharedService) { }

  ngOnInit(): void {
    this.getrouter();
    var data = { "email": this.email, "transactionID": this.trans, "nonmemberid": this.nonmemberid, "eventid": this.eventid, "status": "payment done", "description": "payment", "eventDate": this.eventDate }
    this.service.paymenttrasactionnonmember(data).subscribe(data => {
      this.datae = new Date();
    })
    this.service.paymentnonmember(this.trans).subscribe(data => {
      this.datae = data[0]['created_at']
      setInterval(() => { this.ref.detectChanges(); }, 0);
    })
  }

  backClicked() {
    this._location.back();
  }

  getrouter() {
    this.activatedRoute.params.subscribe(params => {
      const email = params['email'];
      const transaction_id = params['transaction_id'];
      const eventid = params['eventid'];
      const nonmemberid = params['nonmemberid'];
      const eventDate = params['eventDate'];
      this.email = email;
      this.trans = transaction_id;
      this.eventid = eventid;
      this.nonmemberid = nonmemberid;
      this.eventDate = eventDate;
      // console.log("email",email);
      // console.log("membershipplan",membershipplan);
      // console.log("dependent",dependent);
      // console.log("transaction_id",transaction_id);
      // return userId;
    });
  }


}
