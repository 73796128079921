<span id="listhere"></span>
<div class="row">
  <div class="container-fluid p-0">
    <div *ngIf="!updatemember">
      <div *ngIf='ghsadminlogin && userType != "tempadmin"' class="dropdown dropdown-inline mr-2">
        <button style="float: right" class="btn btn-light-primary font-weight-bolder dropdown-toggle"> <span
            class="svg-icon svg-icon-md">
            <!--begin::Svg Icon | path:assets/media/svg/icons/Design/PenAndRuller.svg-->
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
              height="24px" viewBox="0 0 24 24" version="1.1">
              <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect x="0" y="0" width="24" height="24" />
                <path
                  d="M3,16 L5,16 C5.55228475,16 6,15.5522847 6,15 C6,14.4477153 5.55228475,14 5,14 L3,14 L3,12 L5,12 C5.55228475,12 6,11.5522847 6,11 C6,10.4477153 5.55228475,10 5,10 L3,10 L3,8 L5,8 C5.55228475,8 6,7.55228475 6,7 C6,6.44771525 5.55228475,6 5,6 L3,6 L3,4 C3,3.44771525 3.44771525,3 4,3 L10,3 C10.5522847,3 11,3.44771525 11,4 L11,19 C11,19.5522847 10.5522847,20 10,20 L4,20 C3.44771525,20 3,19.5522847 3,19 L3,16 Z"
                  fill="#000000" opacity="0.3" />
                <path
                  d="M16,3 L19,3 C20.1045695,3 21,3.8954305 21,5 L21,15.2485298 C21,15.7329761 20.8241635,16.200956 20.5051534,16.565539 L17.8762883,19.5699562 C17.6944473,19.7777745 17.378566,19.7988332 17.1707477,19.6169922 C17.1540423,19.602375 17.1383289,19.5866616 17.1237117,19.5699562 L14.4948466,16.565539 C14.1758365,16.200956 14,15.7329761 14,15.2485298 L14,5 C14,3.8954305 14.8954305,3 16,3 Z"
                  fill="#000000" />
              </g>
            </svg>
            <!--end::Svg Icon-->
          </span>Export</button>
        <div class="dropdown-content">
          <ul class="navi flex-column navi-hover py-2">
            <li class="navi-header font-weight-bolder text-uppercase font-size-sm text-info pb-2">Choose an option:
            </li>
            <li class="navi-item">
              <a target="_blank" class="navi-link">
                <span class="navi-icon">
                  <i class="fas fa-file-pdf font-weight-bolder text-danger"></i>
                </span>&nbsp;
                <!-- <button class="btn btn-sm btn-primary">Pdf</button><br> -->
                <!-- <button class="btn btn-primary" (click)="openPDF()">Download PDF</button> -->
                <span (click)="openPDF()" title="click to download"
                  class="navi-text font-weight-bolder text-danger">PDF</span>
              </a>
            </li>
            <li class="navi-item">
              <a href="{{APIUrl}}/pdf" class="navi-link">
                <span class="navi-icon">
                  <i class="fas fa-file-pdf font-weight-bolder text-danger"></i>
                </span>&nbsp;
                <!-- <button class="btn btn-sm btn-primary">All Pdf</button><br> -->
                <span class="navi-text font-weight-bolder text-danger" title="click to download">PDF (All
                  Members)</span>
              </a>
            </li>
            <li class="navi-item">
              <a href="{{APIUrl}}/csv" class="navi-link">
                <span class="navi-icon">
                  <i class="fas fa-file-csv font-weight-bolder text-primary"></i>
                </span>&nbsp;
                <span class="navi-text font-weight-bolder text-primary" title="click to download">Excel (All
                  Members)</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf='ghsadminlogin' class="d-flex mb-2">
        <div class="mr-2 chpacs">
          <select (change)="changeprefix()" [(ngModel)]="statusSearch"
            class="text-dark form-control form-control-solid fs-7" style="background-color: #fff !important;">
            <option value="" selected="selected"> Membership Type (All) </option>
            <option *ngFor="let member of memberplanList" value="{{member.prefix}}">{{member.MembershipType}}</option>
          </select>
        </div>
        <div class="mr-2 chpacs">
          <select [(ngModel)]="activeSearch" (change)="changeprefix()"
            class="text-dark form-control form-control-solid fs-7" style="background-color: #fff !important;">
            <option value="" selected="selected">
              <span class="form-text">All Users</span>
            </option>
            <option value="true">Active Users</option>
            <option value="false">InActive Users</option>
          </select>
        </div>
        <div class="mr-2 chpacs">
          <select [(ngModel)]="giftsearch" (change)="changeprefix()"
            class="text-dark form-control form-control-solid fs-7" style="background-color: #fff !important;">
            <option value="" selected="selected">
              <span class="form-text">Member Gift</span>
            </option>
            <option value="true">Received</option>
            <option value="false">Not Received Users</option>
          </select>
        </div>
        <div class="mt-2 me-2 ml-2 chckbxpacs">
          <span class="gradient-icon" *ngIf="loader">
            Searching...
            <span class="spinner-border text-primary spinner-border-sm align-middle ms-2"></span>
          </span>
        </div>
      </div>
      <div *ngIf='ghsadminlogin' class="search-hero mb-2">
        <input class="form-control text-dark" type="text" name="search" (input)="search()" [(ngModel)]="searchText"
          autocomplete="off" placeholder="&#9830; Start Searching for a FamilyID , Name , Email , Address">
      </div>
      <div class="table-container">
        <table id="users">
          <thead>
            <tr>
              <th *ngIf="ghsadminlogin" class="width-30">S.No</th>
              <th class="width-40">Profile</th>
              <th class="width-80">Membership Type</th>
              <th class="width-80">Family ID</th>
              <th class="width-80">Member Name</th>
              <th class="width-150">Member Email</th>
              <th class="width-75 p-2">Contact Phone</th>
              <th class="width-150">Member Address</th>
              <th *ngIf="ghsadminlogin" class="width-30">Gift</th>
              <th class="width-40">Active</th>
              <th class="width-200">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let dataItem of MembersList | paginate : {
            itemsPerPage: tableSize,
            currentPage: page,
            totalItems: count
          }; ; let i=index;">
              <td *ngIf='ghsadminlogin'>{{i+1}}</td>
              <td *ngIf='dataItem.memberImage'><img src="{{APIUrl}}{{dataItem.memberImage}}"
                  style="height: 40px; width:40px">
              </td>
              <td *ngIf='!dataItem.memberImage'><img src="assets/media/users/default.jpg"
                  style="height: 40px; width:40px">
              </td>
              <!-- <td *ngIf='!dataItem.memberImage'><img src="assets/media/bg/bg-10.jpg" style="height: 100px; width:100px"></td> -->
              <!-- <td>{{dataItem.organization}}</td> -->
              <td>{{dataItem.MembershipType}}</td>
              <td>{{dataItem.FamilyPlanId}}</td>
              <!-- <td>{{dataItem.relation}}</td> -->
              <!-- <td contenteditable='true' (input)="onRowClick($event, dataItem.MemberId)">{{dataItem.MemberFirstName}}</td> -->
              <td>{{dataItem.MemberFirstName}} {{dataItem.MemberLastName}}</td>
              <td>{{dataItem.email}}</td>
              <td style='white-space: normal'><a href="tel:{{dataItem.contactPhone}}">{{dataItem.contactPhone}}</a></td>
              <!-- <td >{{dataItem.spouseemail}}</td> -->
              <td style='white-space: normal' *ngIf="dataItem.street">{{dataItem.street}},<br> {{dataItem.city}}
                {{dataItem.state}} {{dataItem.zipcode}}.</td>
              <td style='white-space: normal' *ngIf="!dataItem.street">{{dataItem.city}} {{dataItem.state}}
                {{dataItem.zipcode}}. {{dataItem.is_active}}</td>
              <td *ngIf='ghsadminlogin'><span
                  [title]="dataItem.giftrecieved === 'received' ? 'Gift Received!' : 'Gift Not Received!'">
                  {{ dataItem.giftrecieved === 'received' ? '🎁' : '' }}</span></td>
              <td *ngIf="dataItem.is_active == true"><span title="Validity Expire {{dataItem.validityTo}}"
                  class="label label-success label-inline">Yes </span></td>
              <td *ngIf="dataItem.is_active != true"><span title="Validity Expire {{dataItem.validityTo}}"
                  class="label label-danger label-inline">No</span></td>
              <td style='white-space: nowrap'>
                <button type="button" (click)="onRowClick(dataItem);"
                  class="m-1 p-1 btn btn-light-primary btn-primary font-weight-bold" title="Edit"><i
                    class="fas fa-edit p-0"></i></button>
                <button type="button" routerLink="/family" [state]="{accesstoken : dataItem.access_token}"
                  class="m-1 p-1 btn btn-light-success btn-success font-weight-bold" title="View Family Data"><i
                    class="fas fa-street-view p-0"></i></button>
                <button type="button" routerLink="/renewmember" [state]="{ memberpayment:dataItem.access_token }"
                  class="m-1 p-1 btn btn-light-info btn-info font-weight-bold" title="Renew Membership"><i
                    class="fas fa-dice-d20 p-0"></i></button>
                <button *ngIf='ghsadminlogin' type="button" (click)="openGiftDialog(giftDialog,dataItem)"
                  class="m-1 p-1 btn btn-light-success btn-success font-weight-bold" title="Add Gift"><i
                    class="fas fa-gift p-0"></i></button>
                <button *ngIf='ghsadminlogin' type="button" (click)="openNotesDialog(notesDialog,dataItem)"
                  class="m-1 p-1 btn btn-light-warning btn-warning font-weight-bold" title="Add Notes"><i
                    class="fas fa-sticky-note p-0"></i></button>
                <button *ngIf='ghsadminlogin' type="button" (click)="openModal(dataItem)"
                  class="m-1 p-1 btn btn-light-primary font-weight-bold" title="Send Mail To Member"><i
                    class="fas fa-envelope-open p-0"></i></button>
                <!-- <button type="button" class="m-1 p-1 btn btn-light-success btn-success font-weight-bold">Send Email</button> -->
                <a *ngIf='eventdetails' type="button" target="_blank" routerLink="/viewevent/{{eventdetails.id}}"
                  class="m-1 p-1 btn btn-light-info font-weight-bold" title="View Today's Event">
                  <i class="fas fa-ticket-alt p-0"></i></a>
                <button *ngIf='userType != "tempadmin" && userType != "member"' type="button"
                  (click)="deleteMember(dataItem.MemberId)" class="m-1 p-1 btn btn-light-danger font-weight-bold"
                  title="Delete"><i class="fas fa-trash p-0"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf='ghsadminlogin' class="d-flex justify-content-center mt-10">
        <span class="cursor-pointer label label-primary p-5 label-inline font-weight-bold bd-highlight ml-2">
          Total: {{ MembersList.length }}</span>
        <pagination-controls responsive="true" previousLabel="Prev" nextLabel="Next"
          (pageChange)="onTableDataChange($event)">
        </pagination-controls>
        <div class="d-flex flex-row-reverse bd-highlight mb-2">
          <div class="p-2 bd-highlight">
            <select (change)="onTableSizeChange($event)" style="float: right;" class="custom-select">
              <option *ngFor="let size of tableSizes" [ngValue]="size">
                {{ size }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>



</div>
<!-- 
  Chirag 
  [ngModel] -- for one way binding 
  [(ngModel)] -- fow two way binding 
 -->
<div class="modal modal-sticky modal-sticky-lg modal-sticky-bottom-right" [ngStyle]="{'display':display}"
  id="kt_inbox_compose" role="dialog" data-backdrop="false">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <!-- <form action='' method="POST" onkeydown="return event.key != 'Enter';" enctype="multipart/form-data" -->
      <form action='' enctype="multipart/form-data" id="kt_inbox_compose_form">
        <div class="d-flex align-items-center justify-content-between py-5 pl-8 pr-5 border-bottom">
          <h5 class="font-weight-bold m-0">Compose</h5>
          <div class="d-flex ml-2">
            <span class="btn btn-clean btn-sm btn-icon" (click)="onCloseHandled()" data-dismiss="modal">
              <i class="ki ki-close icon-1x"></i>
            </span>
          </div>
        </div>
        <div class="d-block">
          <div class="d-flex align-items-center border-bottom inbox-to px-8 min-h-45px">
            <div class="text-dark-50 w-75px">To:</div>
            <div class="d-flex align-items-center flex-grow-1">
              <input type="text" class="form-control border-0" name="compose_to" [ngbTypeahead]="search1"
                id="compose_to" [(ngModel)]="compose_to" value="" />
            </div>
            <!-- <div class="ml-2" (click)="showFiller = !showFiller"> -->
            <div class="ml-2">
              <span class="text-muted font-weight-bold cursor-pointer text-hover-primary mr-2" data-inbox="cc-show"
                (click)="showccFiller = !showccFiller">Cc</span>
              <span class="text-muted font-weight-bold cursor-pointer text-hover-primary" data-inbox="bcc-show"
                (click)="showbccFiller = !showbccFiller">Bcc</span>
            </div>
          </div>
          <div *ngIf="showccFiller" class="d-flex align-items-center border-bottom inbox-to px-8 min-h-45px">
            <div class="text-dark-50 w-75px">Cc:</div>
            <div class="d-flex align-items-center flex-grow-1">
              <input type="text" class="form-control border-0" [ngbTypeahead]="search1" name="compose_cc"
                id="compose_cc" [(ngModel)]="compose_cc" value="" />
            </div>
            <span class="btn btn-clean btn-xs btn-icon">
              <i class="fas fa-times" (click)="showccFiller = !showccFiller"></i>
            </span>
          </div>
          <div *ngIf="showbccFiller" class="d-flex align-items-center border-bottom inbox-to px-8 min-h-45px">
            <div class="text-dark-50 w-75px">Bcc:</div>
            <div class="d-flex align-items-center flex-grow-1">
              <input type="text" class="form-control border-0" name="compose_bcc" [ngbTypeahead]="search1"
                id="compose_bcc" [(ngModel)]="compose_bcc" value="" />
            </div>
            <span class="btn btn-clean btn-xs btn-icon">
              <i class="fas fa-times" (click)="showbccFiller = !showbccFiller"></i>
            </span>
          </div>
          <div class="border-bottom">
            <input onkeydown="return event.key != 'Enter';" class="form-control border-0 px-8 min-h-45px"
              name="editor_subject" id="editor_subject" placeholder="Subject" [(ngModel)]="editor_subject" value="" />
          </div>
          <textarea id="editor_value" name="editor_value" [(ngModel)]="editor_value"
            placeholder="&nbsp; &#10; Waiting for your precious content."
            style="padding:20px; height: 300px; width:550px;font-size: 15px;" value=""></textarea>
          <span id="spnFilePath"></span>
        </div>
        <div class="d-flex align-items-center justify-content-between py-5 pl-8 pr-5 border-top">
          <div class="d-flex align-items-center mr-3">
            <div class="btn-group mr-4">
              <button type="submit" (click)="send()" class="btn btn-primary font-weight-bolder">send</button>
            </div>
            <div class="input-group input-group-lg input-group-solid my-2">
              <span class="btn btn-icon btn-sm">
                <input type="file" name="file" (change)="onFileMailAttachmentChange($event)" id="id_inputfiles"
                  class="form-control" multiple hidden />
                <i class="flaticon2-clip-symbol" (click)="attachmentfiles()"></i>
              </span>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <span class="btn btn-icon btn-sm btn-clean" data-dismiss="modal" data-toggle="tooltip" title="Dismiss reply"
              (click)="onCloseHandled()">
              <i class="flaticon2-rubbish-bin-delete-button"></i>
            </span>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<hr>

<div *ngIf="updatemember">
  <div class="card card-body">
    <form [formGroup]="UserForm" (ngSubmit)="onFormSubmit()">
      <!--begin::Title-->
      <div class="pb-10 pb-lg-12 text-center text-md-left">
        <h3 class="font-weight-bolder text-dark font-size-h2">Edit Member Account </h3>
      </div>
      <!--begin::Title-->
      <!--begin::Form Group-->
      <div class="form-group row">
        <label class="col-xl-3 col-lg-3 col-form-label text-left font-weight-bolder text-dark">Profile Image</label>
        <div class="col-lg-9 col-xl-9">
          <div class="image-input image-input-outline">
            <div class="image-input-wrapper">
              <img *ngIf="UserForm.controls.memberImage.value && !imageSrc"
                src="{{APIUrl}}{{UserForm.controls.memberImage.value}}" style="height: 120px; width:120px">
              <img *ngIf="!UserForm.controls.memberImage.value && !imageSrc" src="assets/media/users/default.jpg"
                style="height: 120px; width:120px">
              <img [src]="imageSrc" *ngIf="imageSrc" style="height: 120px; width:120px"><br><br>
            </div>
            <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
              data-action="change" data-toggle="tooltip" title="Change Profile" data-original-title="Change avatar">
              <i class="fa fa-pen icon-sm text-muted"></i>
              <input type="file" (change)="onFileChange($event)" accept=".png, .jpg, .jpeg" />
            </label>
            <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
              (click)="deletePic()" data-action="remove" data-toggle="tooltip" title="Cancel Profile"
              data-original-title="Remove avatar">
              <i class="ki ki-bold-close icon-xs text-muted"></i>
            </span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="form-group col-xl-6 col-lg-6">
          <label for="MemberFirstName" class="font-size-h6 font-weight-bolder text-dark">First Name</label>
          <input type="text"
            [ngClass]="{'is-invalid': UserForm.controls['MemberFirstName'].invalid ,'is-valid': UserForm.controls['MemberFirstName'].valid }"
            [ngStyle]="{'border-bottom': UserForm.controls['MemberFirstName'].invalid ? '1px solid red' : '1px solid green'}"
            class="form-control form-control-solid h-auto p-5  rounded-lg font-size-h6" name="MemberFirstName"
            placeholder="First Name" formControlName="MemberFirstName" />
        </div>
        <div class="form-group col-xl-6 col-lg-6">
          <label for="MemberLastName" class="font-size-h6 font-weight-bolder text-dark">Last Name</label>
          <input type="text" class="form-control form-control-solid h-auto p-5  rounded-lg font-size-h6" name="lastname"
            placeholder="Last Name" value="" formControlName="MemberLastName" />
        </div>
      </div>

      <!--end::Form Group-->
      <!--begin::Form Group-->

      <!--end::Form Group-->
      <!--begin::Form Group-->
      <div class="row">
        <div class="form-group col-xl-6 col-lg-6">
          <label for="email" class="font-size-h6 font-weight-bolder text-dark">Email</label>
          <input type="email"
            [ngClass]="{'is-invalid': UserForm.controls['email'].invalid ,'is-valid': UserForm.controls['email'].valid }"
            [ngStyle]="{'border-bottom': UserForm.controls['email'].invalid ? '1px solid red' : '1px solid green'}"
            class="form-control form-control-solid h-auto p-5  rounded-lg font-size-h6" name="email" placeholder="Email"
            formControlName="email" value="" />
        </div>

        <!--end::Form Group-->
        <!--begin::Form Group-->
        <div class="form-group col-xl-6 col-lg-6">
          <label for="contactPhone" class="font-size-h6 font-weight-bolder text-dark">Contact No</label>
          <input type="text" (input)="validatePhoneNo($event.target)"
            [ngClass]="{'is-valid': UserForm.controls['contactPhone'].valid}"
            class="form-control form-control-solid h-auto p-5  rounded-lg font-size-h6" formControlName="contactPhone"
            name="contactPhone" placeholder="(123) 456-7890" value="" />
        </div>
      </div>
      <!-- <div class="form-group col-xl-6">
      <label for="FamilyId" class="font-size-h6 font-weight-bolder text-dark">Family ID</label>
      <select name="FamilyId" formControlName="FamilyId"
        class="form-control form-control-solid h-auto p-5  rounded-lg font-size-h6">
        <option *ngFor="let dataItem of familyList" [ngValue]="dataItem.FamilyId">{{dataItem.FamilyId}}
          {{dataItem.FamilyName}}</option>
      </select>
    </div> -->
      <!-- <div class="pt-lg-0 pt-5 pb-15 text-center text-md-left">
        <h3 class="font-weight-bolder text-dark font-size-h2">Address Details</h3>
        <div class="text-muted font-weight-bold font-size-h4">Provide Address Details !</div>
      </div> -->
      <!--begin::Title-->
      <!--begin::Row-->
      <div class="row">
        <div class="col-xl-6">
          <!--begin::Input-->
          <div class="form-group">
            <label class="font-size-h6 font-weight-bolder text-dark">Street</label>
            <input type="text"
              [ngClass]="{'is-invalid': UserForm.controls['street'].invalid ,'is-valid': UserForm.controls['street'].valid }"
              formControlName="street" class="form-control form-control-solid h-auto p-5  rounded-lg font-size-h6"
              name="street" placeholder="Address Line 1" value="" />
            <span class="form-text text-muted">Please enter your Address.</span>
          </div>
          <!--end::Input-->
        </div>
        <div class="col-xl-6">
          <!--begin::Input-->
          <div class="form-group">
            <label class="font-size-h6 font-weight-bolder text-dark">City</label>
            <input type="text"
              [ngClass]="{'is-invalid': UserForm.controls['city'].invalid ,'is-valid': UserForm.controls['city'].valid }"
              formControlName="city" class="form-control form-control-solid h-auto p-5  rounded-lg font-size-h6"
              name="city" placeholder="city" value="" />
            <span class="form-text text-muted">Please enter your city.</span>
          </div>
          <!--end::Input-->
        </div>
      </div>
      <!--end::Row-->
      <!--begin::Row-->
      <div class="row">
        <div class="col-xl-6">
          <!--begin::Input-->
          <div class="form-group">
            <label class="font-size-h6 font-weight-bolder text-dark">State</label>
            <select id="id_state" formControlName="state"
              class="form-control form-control-solid h-auto p-5  rounded-lg font-size-h6">
              <option value="">Select State</option>
              <option *ngFor="let value of state" [ngValue]="value[0]">{{value[1]}}</option>
            </select>
            <span class="form-text text-muted">Please enter your State.</span>
          </div>
        </div>
        <input type="number" formControlName="MemberId" value="" hidden />
        <div class="col-xl-6">
          <div class="form-group">
            <label class="font-size-h6 font-weight-bolder text-dark">Zipcode</label>
            <input type="number"
              [ngClass]="{'is-invalid': UserForm.controls['zipcode'].invalid ,'is-valid': UserForm.controls['zipcode'].valid }"
              formControlName="zipcode" class="form-control form-control-solid h-auto p-5  rounded-lg font-size-h6"
              name="zipcode" placeholder="zipcode"
              oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              value="" maxlength="5" />
            <span class="form-text text-muted">Please enter your Zipcode.</span>
          </div>
        </div>
      </div>
      <!--end::Row-->
      <!-- <div class="row">
      <div class="col-xl-6">
        <div class="form-group">
          <label class="font-size-h6 font-weight-bolder text-dark">County</label>
          <input type="text"
            [ngClass]="{'is-invalid': UserForm.controls['county'].invalid ,'is-valid': UserForm.controls['county'].valid }"
            formControlName="county" class="form-control form-control-solid h-auto p-5  rounded-lg font-size-h6"
            name="county" placeholder="county" value="" />
          <span class="form-text text-muted">Please enter your county.</span>
        </div>
      </div>
    </div> -->
      <!--end::Section-->
      <div class="d-flex justify-content-between pt-7">
        <div class="mr-2">
        </div>
        <div>
          <button type="submit" [disabled]="!UserForm.valid"
            class="btn btn-primary font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3">Submit
            <span class="svg-icon svg-icon-md ml-2">
              <!--begin::Svg Icon | path:assets/media/svg/icons/Navigation/Right-2.svg-->
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24" />
                  <rect fill="#000000" opacity="0.3"
                    transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                    x="7.5" y="7.5" width="2" height="9" rx="1" />
                  <path
                    d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                    fill="#000000" fill-rule="nonzero"
                    transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                </g>
              </svg>
              <!--end::Svg Icon-->
            </span></button>
          <button type="button" (click)="cancel();"
            class="m-1 btn btn-light-info font-weight-bolder font-size-h6 pl-8 pr-4 py-4 my-3">Cancel
            <span class="svg-icon svg-icon-md ml-2">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px"
                height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <polygon points="0 0 24 0 24 24 0 24" />
                  <rect fill="#000000" opacity="0.3"
                    transform="translate(8.500000, 12.000000) rotate(-90.000000) translate(-8.500000, -12.000000)"
                    x="7.5" y="7.5" width="2" height="9" rx="1" />
                  <path
                    d="M9.70710318,15.7071045 C9.31657888,16.0976288 8.68341391,16.0976288 8.29288961,15.7071045 C7.90236532,15.3165802 7.90236532,14.6834152 8.29288961,14.2928909 L14.2928896,8.29289093 C14.6714686,7.914312 15.281055,7.90106637 15.675721,8.26284357 L21.675721,13.7628436 C22.08284,14.136036 22.1103429,14.7686034 21.7371505,15.1757223 C21.3639581,15.5828413 20.7313908,15.6103443 20.3242718,15.2371519 L15.0300721,10.3841355 L9.70710318,15.7071045 Z"
                    fill="#000000" fill-rule="nonzero"
                    transform="translate(14.999999, 11.999997) scale(1, -1) rotate(90.000000) translate(-14.999999, -11.999997)" />
                </g>
              </svg>
            </span></button>
        </div>
      </div>
      <!--end: Wizard Actions-->
    </form>
  </div>
</div>

<ng-template #giftDialog let-modal>
  <div class="modal-header text-center p-3">
    <div class="w-100">
      <h5 *ngIf="userInfo" class="modal-title font-weight-bold mb-0" id="modal-title">
        {{userInfo.MemberFirstName}} {{userInfo.MemberLastName}}
      </h5>
    </div>
    <button type="button" class="btn btn-primary" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true" style="font-size: 1.5rem;">&times;</span>
    </button>
  </div>
  <div class="modal-body p-4">
    <div class="col-md-12">
      <div class="form-group">
        <h4 style="color: #154360" class="mb-5"><strong>Did the Member Receive the Gift?</strong>
        </h4>
        <div class="radio-inline mb-5">
          <label class="radio">
            <input type="radio" [value]="true" [(ngModel)]="giftStatus">
            <span></span>Yes
          </label>
          <label class="radio">
            <input type="radio" [value]="false" [(ngModel)]="giftStatus">
            <span></span>No
          </label>
        </div>
        <div class="text-muted mb-5">Select "Yes" if the member has received the gift</div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" (click)="updategiftnote('gift')">Submit</button>
      <button class="btn btn-secondary" (click)="modal.dismiss('Cross click')">Close</button>
    </div>
  </div>

</ng-template>

<ng-template #notesDialog let-modal>
  <div class="modal-header text-center p-3">
    <div class="w-100">
      <h5 class="modal-title font-weight-bold mb-0" id="modal-title">
        {{userInfo.MemberFirstName}} {{userInfo.MemberLastName}}
      </h5>
    </div>
    <button type="button" class="btn btn-primary" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true" style="font-size: 1.5rem;">&times;</span>
    </button>
  </div>
  <div class="modal-body p-4">
    <div class="report-actions mb-4">
      <label class="fs-7">Enter Notes</label>
      <textarea class="form-control" [(ngModel)]="notes" placeholder="Enter your notes"></textarea>
    </div>

    <div class="modal-footer">
      <button class="btn btn-primary" (click)="updategiftnote('notes')">Submit</button>
      <button class="btn btn-secondary" (click)="modal.dismiss('Cross click')">Close</button>
    </div>
  </div>

</ng-template>
<!-- [(ngModel)]="compose_cc" [ngbTypeahead]="search1" value="" />

import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {
  catchError,
  tap,
  map,
  filter,
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from 'rxjs/operators';

emailsuggestions;
search1 = (text$: Observable<string>) =>
text$.pipe(
  debounceTime(200),
  distinctUntilChanged(),
  map(term => term === '' ? []
    : this.emailsuggestions.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
);

var arremail=[];  // to display states use this code
for (var i in data['member']){ 
  console.log("i",data['member'][i]['email'])
  var email = data['member'][i]['email']
  arremail.push(email)
 }
this.emailsuggestions = arremail;
console.log("emails suggestions",this.emailsuggestions) -->