import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { SharedService} from "src/app/shared.service"
import {Router} from '@angular/router';
import {Location} from '@angular/common';
@Component({
  selector: 'app-checkout-ticket-member',
  templateUrl: './checkout-ticket-member.component.html',
  styleUrls: ['./../../modules/user-profile/profile-overview/profile.css'],
})
export class CheckoutTicketMemberComponent implements OnInit {

  email: string;
  eventDate:string;
  trans: string;
  dependent;
  membershipplan: string;
  amount;
  curDate=new Date();
  eventid;
  memberid;
  ticket;
  constructor(private activatedRoute: ActivatedRoute,private ref: ChangeDetectorRef, private _location: Location,private service:SharedService ) { }

  ngOnInit(): void {
    this.getrouter();
    var datacheck = { "email" : this.email ,"dependent":this.ticket,"memberid":this.memberid ,"transactionID" : this.trans ,"productName" : "GSH Event Members Ticket" , "price":this.amount , "description":this.eventid , "eventDate":this.eventDate,"paymentType": "Event"}
    this.service.paymenttrasactionmember(datacheck).subscribe(data=> {
      // console.log("data payment",data)
    })
  }

  backClicked() {
    this._location.back();
  }
   
  getrouter(){
    this.activatedRoute.params.subscribe(params => {
      const email = params['email'];
      const eventDate = params['eventDate'];
      const memberid = params['memberid'];
      const eventid = params['eventid'];
      const ticket = params['ticket'];
      const amount = params['amount'];
      const transaction_id = params['transaction_id'];
      this.email = email;
      this.eventDate = eventDate;
      this.trans = transaction_id;
      this.eventid = eventid;
      this.amount = amount;
      this.memberid = memberid;
      this.ticket = ticket;
      // console.log("email",email);
      // console.log("membershipplan",membershipplan);
      // console.log("dependent",dependent);
      // console.log("transaction_id",transaction_id);
      // return userId;
    });
  }


}