<style>
    .container {
        width: 580px;
        padding: 20px 0 30px 0;
        margin: 0 auto;
        margin-top: 20px;
        border-radius: 8px;
        box-shadow: 0 3px 20px rgba(0,0,0,0.20);
    }

    h1 {
        text-align: center;
        font-size: 24px;
        font-family: sans-serif;
    }

    h2 {
        text-align: center;
        font-size: 20px;
        font-family: sans-serif;
    }
    h3 {
        text-align: center;
        font-family: sans-serif;
    }
    .footer {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        color: white;
        text-align: center;
     }
</style>

<html>
    <body>
        <a class="text-center mb-10"><img class="img-fluid" src="./assets/media/logos/gshbanner.jpg"
            style="width: auto; height: auto;" alt="" /></a>
        <div style="background-color: greenyellow;" class="container">
            <div class="row">
                <div class="col-md-12">
                    <h1 style="color:darkgreen; font-weight:bolder"> Payment Successfull</h1><br>
                    <h3 style="color:black">Transaction Complete for {{email}} </h3> <br>
                    <h3 style="color:black">Transaction ID {{trans}}</h3> <br>
                    <h3 style="color:black">Date: {{curDate | date:"MMM d y, h:mm"}} </h3> <br>
                    <p style="color:darkgray; text-align:center">Receipt is Generated and Sent to your Email.</p>
                </div>
            </div>
            <button type="button" style="background-color: #0BD992;font-family: sans-serif;" routerLink="/auth/memberlogin" style="float:right;" title="Login" class="btn btn-success font-weight-bold">Continue</button>
        </div>
        <div class="circle1"></div>
        <div class="circle2"></div>
        <div class="circle3"></div>
        <div class="circle4"></div>
        <div class="circle5"></div>
        <div class="circle6"></div>
        <div class="circle7"></div>
        <div class="circle8"></div>
    </body>
    <div class="card footer d-flex flex-column flex-md-row align-items-center justify-content-between">
    <div class="text-dark order-2 order-md-1">
      <span class="text-muted font-weight-bold mr-2">2021 &copy;</span>
      <a href="https://shuklais.com" target="_blank" class="text-dark-75 text-hover-primary">Shukla Information
        Solutions</a>
    </div>
    <div class="nav nav-dark order-1 order-md-2">
      <a href="./#/about" class="nav-link pr-3 pl-0">About</a>
      <a href="./#/leadership" class="nav-link px-3">Leadership</a>
      <a href="./#/contact" class="nav-link pl-3 pr-0">Contact</a>
    </div>
  </div>
</html>
