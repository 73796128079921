<!-- <h1>Manage Organizer Board</h1> -->
<div [hidden]="updateMemberBoard">
  <div class="card">
    <div class="row mb-2">
      <div class="col-lg-8">
        <div class="search-hero">
          <input class="form-control" type="text" name="search" [(ngModel)]="searchText" autocomplete="off"
            placeholder="&#61442;  Start searching for a name , email , phone no">
        </div>
      </div>
      <div class="col-lg-3">
        <select id="searchmembertype" (change)="searchmembertype($event.target.value)"
          class="form-control form-control-solid">
          <option value="All">All</option>
          <option value="Excecutive Member">Excecutive Member</option>
          <option value="Trustee">Trustee</option>
          <option value="Temp Admin">Temp Admin</option>
        </select>
      </div>
    </div>
    <div class="row">
    </div>
  </div>
  <div class="table-responsive">
    <table id="users" class="table table-striped">
      <thead>
        <tr>
          <th style="width:35px">S.No</th>
          <th style="width:53px">Profile</th>
          <th>Name</th>
          <th>Email</th>
          <th>Phone No</th>
          <th>Member Type</th>
          <th>Actions</th>
      </thead>
      <tbody>
        <tr *ngFor="let dataItem of memberplanList | blank:searchText  ; let i=index;">
          <td>{{i+1}}</td>
          <div *ngIf='dataItem.memberID'>
            <td *ngIf='dataItem.memberID.memberImage'><img src="{{APIUrl}}{{dataItem.memberID.memberImage}}"
                style="height: 40px; width:40px">
            </td>
            <td *ngIf='!dataItem.memberID.memberImage'><img src="assets/media/users/default.jpg"
                style="height: 40px; width:40px">
            </td>
          </div>
          <div *ngIf='!dataItem.memberID'>
            <td><img src="assets/media/users/default.jpg" style="height: 40px; width:40px">
            </td>
          </div>
          <td>{{dataItem.fName}} {{dataItem.lName}}</td>
          <td>{{dataItem.email}}</td>
          <td>{{dataItem.contactPhone}}</td>
          <td>{{dataItem.memberBoardType}}</td>
          <td>
            <button type="button" (click)='editMemberBoard(dataItem.id)' class="btn btn-success mr-2"> Edit</button>
            <button type="button" (click)='deleteFamily(dataItem.id)' class="btn btn-light mr-1 greenText"> Remove
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


<div [hidden]="!updateMemberBoard" class="container" style="padding-top:40px;">
  <div class="row">
    <div class="col-md-6 mx-auto">
      <div class="card mx-4">
        <div class="card-body p-4">
          <form [formGroup]="UserForm" onkeydown="return event.key != 'Enter';" (ngSubmit)="onFormSubmit()">
            <i style="float:right" title="back" (click)="cancel();"
              class="text-success fa-2x fas fa-arrow-circle-left"></i>
            <h4 class="font-weight-bolder font-size-h4" style="text-align: center; color:blue">Edit Executive / Trustee
              /
              TAdmin Member</h4>
            <label for="memberBoardType" class="font-weight-bolder text-dark">Member Type</label>
            <div class="input-group mb-3">
              <select name="memberBoardType" formControlName="memberBoardType"
                class="form-control form-control-solid h-auto p-5  rounded-lg font-size-h6">
                <option value="Excecutive Member">Excecutive Member</option>
                <option value="Trustee">Trustee</option>
                <option value="Temp Admin">Temp Admin</option>
              </select>
            </div>
            <div class="form-group row">
              <label class="col-xl-6 col-lg-6 col-form-label font-weight-bolder">Profile Image</label>
              <div class="col-lg-9 col-xl-12">
                <div class="image-input image-input-outline" id="kt_profile_avatar">
                  <div *ngIf="UserForm.controls.image.value">
                    <div class="image-input-wrapper" *ngIf="!imageSrc">
                      <img src="{{APIUrl}}{{UserForm.controls.image.value}}" style="height: 120px; width:120px"><br><br>
                    </div>
                  </div>
                  <div *ngIf="!UserForm.controls.image.value">
                    <div class="image-input-wrapper" *ngIf="!imageSrc">
                      <img src="assets/media/users/default.jpg" style="height: 120px; width:120px"><br><br>
                    </div>
                  </div>
                  <div class="image-input-wrapper" *ngIf="imageSrc">
                    <img [src]="imageSrc" *ngIf="imageSrc" style="height: 120px; width:120px"><br><br>
                  </div>
                  <label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
                    <i class="fa fa-pen icon-sm text-muted"></i>
                    <input type="file" (change)="onFileChange($event)" name="profile_avatar"
                      accept=".png, .jpg, .jpeg" />
                    <input type="hidden" name="profile_avatar_remove" />
                  </label>
                  <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="cancel" data-toggle="tooltip" title="" data-original-title="Cancel avatar">
                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                  </span>
                  <span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    (click)="deletePic()" data-action="remove" data-toggle="tooltip" title=""
                    data-original-title="Remove avatar">
                    <i class="ki ki-bold-close icon-xs text-muted"></i>
                  </span>
                </div>
                <span class="form-text text-muted">Allowed file types: png, jpg, jpeg.</span>
              </div>
            </div>
            <label class="font-weight-bolder text-dark" for="fName">First Name</label>
            <div class="input-group mb-3">
              <input type="text"
                [ngClass]="{'is-invalid': UserForm.controls['fName'].invalid ,'is-valid': UserForm.controls['fName'].valid }"
                class="form-control form-control-solid h-auto p-5  rounded-lg font-size-h6" placeholder="First Name"
                formControlName="fName">
            </div>

            <label class="font-weight-bolder text-dark" for="lName">Last Name</label>
            <div class="input-group mb-3">
              <input type="text" class="form-control form-control-solid h-auto p-5  rounded-lg font-size-h6"
                placeholder="Last Name" formControlName="lName">
            </div>
            <label class="font-weight-bolder text-dark" for="email">Email</label>
            <div class="input-group mb-3">
              <input type="email"
                [ngClass]="{'is-invalid': UserForm.controls['email'].invalid ,'is-valid': UserForm.controls['email'].valid }"
                class="form-control form-control-solid h-auto p-5  rounded-lg font-size-h6" placeholder="Email"
                formControlName="email">
            </div>
            <div
              *ngIf="UserForm.controls['email'].invalid && (UserForm.controls['email'].dirty || UserForm.controls['email'].touched)"
              class="alert alert-danger">Email is not Valid.
              <div *ngIf="UserForm.controls['email'].errors.required">Email is required.</div>
            </div>
            <label class="font-weight-bolder text-dark" for="contactPhone">Contact No </label>
            <div class="input-group mb-3">
              <input type="text"
                [ngClass]="{'is-invalid': UserForm.controls['contactPhone'].invalid ,'is-valid': UserForm.controls['contactPhone'].valid }"
                class="form-control form-control-solid h-auto p-5  rounded-lg font-size-h6"
                (input)="validatePhoneNo($event.target)" placeholder="(123) 456-7890" formControlName="contactPhone">
            </div>
            <div
              *ngIf="UserForm.controls['contactPhone'].invalid && (UserForm.controls['contactPhone'].dirty || UserForm.controls['contactPhone'].touched) || !isValidFlg"
              class="alert alert-danger">Invalid Format</div>
            <label for="memberBoardType" class="font-weight-bolder text-dark">Validity Start</label>
            <div class="input-group mb-3">
              <mat-form-field>
                <input matInput (dateChange)="onChangeEvent6($event)" [matDatepicker]="picker" value=""
                  placeholder="Choose a date" formControlName="validityFrom" />
                <mat-datepicker-toggle matSuffix [for]="picker">`
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <label for="memberBoardType" class="font-weight-bolder text-dark">Validity End</label>
            <div class="input-group mb-3">
              <mat-form-field>
                <input matInput [matDatepicker]="picker1" placeholder="Choose a date"
                  (dateChange)="onChangeEvent($event)" formControlName="validityTo" />
                <mat-datepicker-toggle matSuffix [for]="picker1">
                </mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="row">
              <div class="col-md-6">
                <button type="submit" [disabled]="!UserForm.valid"
                  class="btn btn-block btn-success font-weight-bolder">Update
                  Member Board</button>
              </div>
              <div class="col-md-6">
                <button type="button" (click)="cancel();" class="btn btn-block btn-light-info font-weight-bolder">Cancel
                  / View Listing
                </button>
              </div>
            </div>
            <!-- <div *ngIf="UserForm.controls['contactPhone'].touched && UserForm.controls['contactPhone'].invalid" class="alert alert-danger">  </div> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</div>